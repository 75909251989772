import React from "react";
import tw from "twin.macro";

const Container = tw.div` font-comicsans`;
const PrivacySection = tw.div`md:mx-64 mx-4 md:my-20`;

const Heading = tw.div`text-5xl text-center mx-64 my-24 font-black text-blue-900`;
const SubHeading = tw.div`text-lg my-4 text-textprimary font-semibold`;
const Paragraph = tw.div`opacity-75 text-lg`;

export default () => {
  return (
    <Container>
      <Heading>Privacy Policy</Heading>

      <PrivacySection>
        <SubHeading>What data will be collected?</SubHeading>
        <Paragraph>
          When you play the memory game, we collect general information about
          how you interact with it. You may also choose to disclose your age
          range, interests, and demographic information, including your gender
          and ethnicity. We’re collecting this information because we want to
          understand more about how memory works
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>What will we use data for?</SubHeading>
        <Paragraph>
          We will use the information we collect within the memory game to
          conduct research on what aspects make something harder or easier to
          remember. <br /> <br /> We will not use the information for any
          commercial or any other internal purpose other than to provide
          de-identified game activity and the additional information you share
          to researchers for purposes of the research. The data associated with
          your device will be anonymized by 3HLearning as soon as the research
          is completed, at which point 3HLearningwill no longer retain personal
          information about you or your device that has been collected through
          the memory game.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>With whom will my information be shared?</SubHeading>
        <Paragraph>
          We may share de-identified game activity and the additional
          information you share with researchers. The information shared with
          researchers will not contain any personal information you share
          directly with us. It may include device level information, which would
          only be provided only in aggregated and anonym zed form.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>How will the results be used? </SubHeading>
        <Paragraph>
          The memory game is designed to measure and track improvement on a
          memorization task over a series of sessions. Do players’ memorization
          abilities get better over time? What factors make that more likely?
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Legal basis</SubHeading>
        <Paragraph>
          We may process the information gathered from your interaction with the
          memory game according to our legitimate interest in conducting the
          research. We may also collect and use the demographic information you
          may choose to provide based on your prior consent.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Payment Methods</SubHeading>
        <Paragraph>
          You can use payment methods like Credit Card, Debit Card, Upi or
          Netbanking for payment.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Refund Policy</SubHeading>
        <Paragraph>
          There is no refund avialable based on the privacy policy. For more
          details contact us at 3h@3hlearning.com through email or call us on
          044-45564344 / 044-48574344 .
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>How can I manage my information?</SubHeading>
        <Paragraph>
          You may consult our Privacy Policy for more information on your
          choices and rights regarding personal data. You may also send us a
          direct inquiry about your data via our webform or email us at
          3hlearningindia@gmail.com.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Social Sharing Info</SubHeading>
        <Paragraph>
          The App features social sharing which let you share actions you take
          in the App with other media. As a matter of fact, you must have the
          minimum age limit that is prescribed by the legislation in the
          individual jurisdictions to use social sharing features integrated in
          the App. The use of such features enables sharing of information with
          friends or public, as per the settings you perform with the entity
          that provides the social sharing feature. <br />
          For more information about the processing carried out by the
          respective third parties in connection with social sharing features,
          please visit the privacy policies of the entities that provide these
          features, which can be found here: Google: <br />
          <br />
          https://policies.google.com/privacy/update <br />
          <br />
          Facebook: https://www.facebook.com/about/privacy/ <br />
          <br />
          Twitter: https://twitter.com/en/privacy <br />
          <br />
          Game Center:
          https://www.apple.com/legal/internetservices/itunes/gamecenter/ <br />
          <br />
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Purpose of Personal Data </SubHeading>
        <Paragraph>
          We will use the information you provide to create a profile of your
          interests so we can offer events & services based on your preferences.
          We would like to process your concerns and provide you with services
          and information, improve the layout and content of the pages of the
          App and customize them. <br /> <br />
          We can also carry out research on our users’ tracking of usage data.
          In addition to that, we can help you share with social networking
          sites at your request; and disclose to legal and lawful authorities if
          required to do so by law.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Reasons/ Factors on which your data is used:</SubHeading>
        <Paragraph>
          1. Data is used so that we can give the best experience to clients and
          improve our products, services, and advertising process. <br />
          <br /> 2. We may collect data regarding customer activities on app and
          is used to help us provide more useful information to our customers
          and to understand which parts of our app, products, and services are
          of most interest.
          <br />
          <br />
          3. We may collect and store details which may be used to improve the
          relevancy of results provided by our products and services with
          marketing strategy. The personal data have been collected in relation
          to the offer of information society services. Please note that your
          right to erasure may be limited if the data are necessary for
          compliance with a legal obligation or for the defense of legal claims.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Storage, Security and Sharing Features</SubHeading>
        <Paragraph>
          Storage: The information that you submit via the app is stored on
          secured servers and is essential in order to process the information
          and improve customer’s service. It may be transferred by us to the
          third parties in certain circumstances. By submitting information via
          the App, you agree to this storing, processing and/or transfer. <br />
          Security: illusion spot line assure to take reasonable measures to
          help protect information about you. We do not sell, trade, or rent
          users personal identification information to others. <br />
          Sharing: We may share information about you as follows or as otherwise
          described in this Privacy Notice.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Children's Privacy </SubHeading>
        <Paragraph>
          Our services are not directed to children and we don’t direct them to
          anyone under 13. <br />
          We abide by the Children’s Online Privacy Protection Act (“COPPA”).{" "}
          <br />
          If a user gets identified as a child under this prescribed age within
          the Services, Vannala Mobile Apps will not collect, store or use any
          information of such user. <br />
          illusion spot line also does not allow third party partners to access
          personally identifiable data from users under 13. <br />
          In addition, we may limit how we collect, use, and store some of the
          information of EU users between 13 and 16. Moreover, under any prior
          circumstances, illusion spot line received personal information and
          discovered that was provided by a child under the age of 13, illusion
          spot line will promptly delete such personal information in a secure
          manner.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Rights of an Individual </SubHeading>
        <Paragraph>
          You have the right to request access into the data that we are
          processing on you, including information about: <br />
          The purposes of the processing , categories of personal data concerned
          are used. <br />
          The recipients to whom the personal data will be disclosed. <br />
          The envisaged period for which the personal data will be stored.
          Furthermore, you have the right to obtain a copy of the personal data
          undergoing processing. Please note that the access may be restricted
          due to intellectual property or trade secrets
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Right To Object </SubHeading>
        <Paragraph>
          You have the right to object to your personal data processing on
          grounds relating to your particular situation. In this case, we will
          cease the processing unless there are compelling legitimate grounds
          for the processing which override your interests, rights and freedoms
          or if the processing is necessary for the establishment, exercise or
          defense of legal claims. You have the right to object to process of
          your personal data for direct marketing. We will cease the processing
          of data for this purpose after objection. Please note that if you
          exercise this right, your user license to use the App will cease
          automatically.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Right To Rectification And Erasure </SubHeading>
        <Paragraph>
          You have right to have inaccurate personal data rectified and erased.
          Furthermore, you can have your personal data where one of the
          following grounds applies: <br />
          The personal data are no longer necessary in relation to the purposes
          for which they were collected or otherwise processed. <br />
          If you have withdrawn your consent, or objected to the processing and
          there are no legitimate grounds The personal data have to be erased
          for compliance with a legal obligation, or it has been unlawfully
          processed. <br />
          The personal data have been collected in relation to the offer of
          information society services. Please note that your right to erasure
          may be limited if the data are necessary for compliance with a legal
          obligation or for the defense of legal claims.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Right To Withdraw Consent </SubHeading>
        <Paragraph>
          If we have asked for your consent to our processing of your data, you
          have the right to withdraw your consent at any time. <br /> If you
          withdraw your consent, we will cease processing of the data, unless
          any legal obligation to keep parts of your data. The withdrawal of
          your consent does not affect the lawfulness of processing based on
          your consent before its withdrawal.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Push notifications </SubHeading>
        <Paragraph>
          We send push notifications or alerts to your device to provide
          information, updates, & promotional communications, only if you have
          agreed to such notifications. You can deactivate these notifications
          by changing your notification settings on your device.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}

      <PrivacySection>
        <SubHeading>Contact Details</SubHeading>
        <Paragraph>
          If you have any questions regarding the Privacy Policy, please contact
          us at the following email address: 3hlearningindia@gmail.com.
        </Paragraph>
      </PrivacySection>
      {/* --------------------------------------------- */}
    </Container>
  );
};
