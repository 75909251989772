import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import NavBar from "./Components/NavBar";
import HomePage from "./Pages/HomePage";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import OurApplication from "../src/Pages/OurApplications";
import OurApplicationsNew from "../src/Pages/OurApplicationsNew";
import DialogueBooster from "../src/Pages/DialogueBooster";
import SignUp from "./Pages/SignUp";
import SignIn from "./Pages/SignIn";
import Footer from "../src/Components/Footer";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import PaymentPolicy from "./Pages/PaymentPolicy";
import Terms from "./Pages/Terms";
import SiteMap from "./Pages/SiteMap";
import OurBooks from "./Pages/OurBooks";
import PreKG from "./Pages/PreKG";
import LKG from "./Pages/LKG";
import UKG from "./Pages/UKG";
import STD1 from "./Pages/STD1";
import BeginnersHindi from "./Pages/BeginnersHindi";
import BeginnersTamil from "./Pages/BeginnersTamil";
import EnglishCursive from "./Pages/EnglishCursive";
import ReactModal from "./Components/ReactModal";
import Tab1 from "./Components/OurApplications/Tab1";
import Tab2 from "./Components/OurApplications/Tab2";
import Tab3 from "./Components/OurApplications/Tab3";

export default function App() {
  return (
    <>
      <NavBar />
      <Router>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/AboutUs" element={<AboutUs />} />
          <Route exact path="/OurBooks" element={<OurBooks />} />
          <Route exact path="/PreKG" element={<PreKG />} />
          <Route exact path="/LKG" element={<LKG />} />
          <Route exact path="/UKG" element={<UKG />} />
          <Route exact path="/STD1" element={<STD1 />} />
          <Route exact path="/BeginnersHindi" element={<BeginnersHindi />} />
          <Route exact path="/BeginnersTamil" element={<BeginnersTamil />} />
          <Route exact path="/EnglishCursive" element={<EnglishCursive />} />
          <Route
            exact
            path="/OurApplicationsNew"
            element={<OurApplicationsNew />}
          />
          <Route exact path="/DialogueBooster" element={<DialogueBooster />} />
          <Route exact path="/ReactModal" element={<ReactModal />} />

          <Route exact path="/ContactUs" element={<ContactUs />} />
          <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route exact path="/PaymentPolicy" element={<PaymentPolicy />} />
          <Route exact path="/Terms" element={<Terms />} />
          <Route exact path="/SiteMap" element={<SiteMap />} />
          <Route exact path="/SignIn" element={<SignIn />} />
          <Route exact path="/SignUp/:testvalue" element={<SignUp />} />

          <Route exact path="/VocaBuilderSolo" element={<Tab1 />} />
          <Route exact path="/VocaBuilderDuo" element={<Tab2 />} />
          <Route exact path="/VocaBuilderToto" element={<Tab3 />} />
        </Routes>
      </Router>

      <Footer />
    </>
  );
}
