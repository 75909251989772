import React from "react";
import tw from "twin.macro";

import SliderLeiureTime from "../BeginnerTamil/SliderLeisureTimeOne";

const Container = tw.div` 2xl:mx-64 mx-20 my-4`;
const LeftContainer = tw.div`w-3/4 pr-16`;
const RightContainer = tw.div`w-1/4 `;
const FlexContainer = tw.div`flex items-center`;

const SubHeading = tw.div`text-xl italic my-4 text-textprimary font-semibold`;
const Heading = tw.div`text-4xl text-center mb-8 font-black text-textprimary`;
const Paragraph = tw.div`text-lg my-4 `;
const TableContainer = tw.div`w-1/2`;

const ListParagraph = tw.li` text-lg my-4 `;

export default () => {
  return (
    <Container>
      <Heading>Edu-Pack – Level 1 & 2</Heading>
      <FlexContainer>
        <LeftContainer>
          <Paragraph>
            Beginner’s Tamil Level 1 starts with Uyir ezhuthukkal, Mei
            ezhuthukkal and Uyir Mei exhuthukkal and more of it comes in Level
            2. In addition, there are rhymes, stories, picture reading, simple
            Tamil dialogues, GK concepts, Numbers etc.
            <br /> <br />
            Thick 80 GSM paper allows no see-through.
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Colorful layout,
            </span>{" "}
            lucid presentation and attractive pictures make the book an
            interesting read for the Children who have always seen the language
            books boring.
          </Paragraph>

          <FlexContainer>
            <TableContainer>
              <SubHeading>Lessons Multimedia</SubHeading>

              <ListParagraph>Thematic Digital Lessons</ListParagraph>
              <ListParagraph>• Involves Cartoon Characters</ListParagraph>
              <ListParagraph>
                Integrated with every lesson in the Textbook
              </ListParagraph>
              <ListParagraph>• Learning become multi-sensory</ListParagraph>
              <ListParagraph>
                Best suited for the language learners.
              </ListParagraph>
              <ListParagraph>
                Subjects are learnt better when the children are able to SEE,
                LISTEN & REPEAT
              </ListParagraph>
            </TableContainer>
            <TableContainer>
              <SubHeading>Applications Multimedia </SubHeading>

              <ListParagraph>
                Based on the Principle - ‘No Child should be left out’
              </ListParagraph>
              <ListParagraph>
                Digital Practical helps the Teacher find out if all the concepts
                are understood by all the Children.
              </ListParagraph>
              <ListParagraph>
                Ensures that the building blocks are on firm footing during the
                formative years
              </ListParagraph>
            </TableContainer>
          </FlexContainer>
        </LeftContainer>

        <RightContainer>
          <SliderLeiureTime />
        </RightContainer>
      </FlexContainer>
    </Container>
  );
};
