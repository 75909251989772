import React from "react";
import tw from "twin.macro";

import SliderLeiureTime from "../STD1/SliderLeisureTimeFour";
import SliderLeiureTimeNew from "../STD1/SliderLeisureTimeFourNew";

const Container = tw.div` 2xl:mx-64 mx-20 my-4`;
const LeftContainer = tw.div`w-3/4 pr-16`;
const RightContainer = tw.div`w-1/4`;

const SecondLeftContainer = tw.div`w-1/4 `;
const SecondRightContainer = tw.div`w-3/4 pl-16 flex justify-end`;
const FlexContainer = tw.div`flex items-center`;

const Heading = tw.div`text-4xl text-center mb-8 font-black text-textprimary`;
const Paragraph = tw.div`text-lg my-4 `;

const ListParagraph = tw.li`text-lg my-4 `;

export default () => {
  return (
    <Container>
      <Heading>ACE – Not just some Worksheets for Std 1</Heading>
      <FlexContainer>
        <LeftContainer>
          <ListParagraph>
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              'Power packed learning activities'
            </span>{" "}
            helps get a better Conceptual Understanding
          </ListParagraph>
          <ListParagraph>
            Building a strong foundation thru' step-by-step learning Not just
            assessing if the Kid got it right/wrong
          </ListParagraph>
          <ListParagraph>
            Retain the{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Basics needed for a lifetime{" "}
            </span>{" "}
            during the formative years
          </ListParagraph>
          <ListParagraph>
            394 Learning Objective / 6 books: English 1&2, Mathematics 1&2, EVS
            & Fusion Activities
          </ListParagraph>
          <ListParagraph>
            Fusion Activities: A first of its kind -{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Integrates & Involves multiple subjects{" "}
            </span>{" "}
            across many single activities
          </ListParagraph>
          <ListParagraph>
            Pillars of Learning: LISTENING, SPEAKING, READING, WRITING,
            VOCABULARY, GRAMMER, CONNECT WITH ONESELF, LET'S TALK
          </ListParagraph>
          <ListParagraph>
            LEARN Part:{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Revise key concepts{" "}
            </span>{" "}
            before doing activities; Serves as standalone books
          </ListParagraph>

          <ListParagraph>
            EXAMPLE Part: helps to understand what to do & how to do
          </ListParagraph>
        </LeftContainer>

        <RightContainer>
          <SliderLeiureTime />
        </RightContainer>
      </FlexContainer>

      <FlexContainer>
        <SecondLeftContainer>
          <SliderLeiureTimeNew />
        </SecondLeftContainer>

        <SecondRightContainer>
          <div>
            <ListParagraph>
              EDGE ACTIVITIES: Kids learn by doing Practical Activities
            </ListParagraph>
            <ListParagraph>
              HOTS{" "}
              <span
                style={{
                  color: "#1E3A8A",
                  fontWeight: "bold",
                  fontSize: "105%",
                }}
              >
                {" "}
                (Higher Order Thinking Activities){" "}
              </span>{" "}
              :Challenging activities develops Critical Thinking
            </ListParagraph>
            <ListParagraph>
              Context to Concept (C2C): Understand & use concepts for daily
              usage
            </ListParagraph>
            <ListParagraph>
              Educative, Interesting & Innovative: Develops the child's thinking
              skills
            </ListParagraph>
            <ListParagraph>
              <span
                style={{
                  color: "#1E3A8A",
                  fontWeight: "bold",
                  fontSize: "105%",
                }}
              >
                {" "}
                Bite-Sized:{" "}
              </span>{" "}
              Learning happens in small & easy chunks
            </ListParagraph>
            <ListParagraph>Find & Fix Learning-gaps </ListParagraph>
            <ListParagraph>Quality Time productively spent </ListParagraph>
            <ListParagraph>
              <span
                style={{
                  color: "#1E3A8A",
                  fontWeight: "bold",
                  fontSize: "105%",
                }}
              >
                {" "}
                Lakhs of satisfied ACE users{" "}
              </span>{" "}
              across India in 10Yrs
            </ListParagraph>
          </div>
        </SecondRightContainer>
      </FlexContainer>

      <Paragraph>
        Incomparable ACE: Quality Coverage, Depth, Lucid Presentation, Practical
        Angle, Range of Activities, Usefulness, Easy to Understand, Enjoyable to
        USE, Paper Quality, More Pages, Value Pricing
      </Paragraph>
    </Container>
  );
};
