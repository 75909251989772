import React from "react";
import tw from "twin.macro";

import SliderLeiureTime from "../EnglishCursive/SliderLeisureTimeThree";

const Container = tw.div` 2xl:mx-64 mx-20 my-4`;
const LeftContainer = tw.div`mx-56 items-center`;
const RightContainer = tw.div`mx-32 my-8`;

const SubHeading = tw.div`text-xl italic my-4 text-textprimary font-semibold`;
const Paragraph = tw.div`text-lg my-4 `;

const ListParagraph = tw.li` text-lg my-4 `;

export default () => {
  return (
    <Container>
      <LeftContainer>
        <SliderLeiureTime />
      </LeftContainer>

      <RightContainer>
        <Paragraph>
          Good Handwriting is a necessary part of Education - Mahatma Gandhiji!
          <br /> <br />
          Good handwriting always gives everyone a lot of confidence. If your
          School wants to give best handwriting to your students, your search
          stops here!
        </Paragraph>

        <SubHeading>
          PractiZ – fun@home - English Cursive Writing Books
        </SubHeading>
        <ListParagraph>
          A Combo of 3 books with 100 pages each (totally 300 pages) is just
          what your School needs to give your students that perfect and legible
          handwriting. Schools have the option to order any of the three levels
          separately also.
        </ListParagraph>
        <ListParagraph>
          Your students can be guided step-by-step with pre-written content that{" "}
          <span
            style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
          >
            saves up to 300 hours of precious time of your teachers.{" "}
          </span>
        </ListParagraph>
        <ListParagraph>
          By spending just 30 minutes daily for a year, their writing will start
          improving slowly. The process is fun and easy.
        </ListParagraph>
        <ListParagraph>
          <span
            style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
          >
            {" "}
            Very thick and high quality paper{" "}
          </span>{" "}
          helps your students to even erase once written material and practice
          them again with ease.
        </ListParagraph>
        <ListParagraph>
          This book can be useful for the adults to enrich their writing style.
        </ListParagraph>
        <ListParagraph>
          The writing practice includes lowercase and uppercase letters, words,
          sentences, and more.
        </ListParagraph>
        <ListParagraph>
          During summer holidays, engage your child productively with this easy
          learning activity book which{" "}
          <span
            style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
          >
            {" "}
            helps them to have excellent handwriting.{" "}
          </span>
        </ListParagraph>
      </RightContainer>
    </Container>
  );
};
