/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { ApplicationsArray } from "./ApplicationsArray.js";
import { Link } from "react-router-dom";
import { auth, db } from "../Components/FirebaseInit.js";
import { doc, getDoc } from "firebase/firestore";
import { Navigate } from "react-router-dom";
import tw from "twin.macro";
const Container = tw.div`my-20`;
const Gallery = tw.div`flex flex-wrap justify-center md:mx-64 my-10`;
const TabsContainer = tw.div`rounded-xl w-80 mx-10 my-10 flex items-center place-content-center hocus:border-b-8 hocus:border-red-500`;
const Heading = tw.div`text-4xl text-center font-black text-textprimary`;
const Paragraph = tw.div`text-base my-2 opacity-70 text-center`;
export default () => {
  const [on_application_clicked, set_on_application_clicked] = useState(false);
  const [selected_application_name, set_selected_application_name] =
    useState("");
  const [selected_application_link, set_selected_application_link] =
    useState("");
  const [is_application_available, set_is_application_available] =
    useState(false);
  const VerificationProcess = (name, application_link) => {
    set_selected_application_link("");
    set_on_application_clicked(true);
    set_selected_application_name(name);
    set_is_application_available(false);
    CheckPatreon(name, application_link);
  };
  async function CheckPatreon(name, application_link) {
    const docRef = doc(db, name, auth.currentUser.phoneNumber.slice(1));
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      set_selected_application_link(application_link);
      set_is_application_available(true);
      console.log("Document data:", docSnap.data());
    } else {
      set_is_application_available(false);
      console.log("No Data Found");
      <Navigate to="/SignUp" />;
    }
  }
  return (
    <Container>
      <Heading>Our Desktop Applications</Heading>

      {/* <Paragraph>There are many variations of passages. But the majority have suffered alteration in some form, by injected humour, or randomised words.</Paragraph> */}

      <Gallery>
        {ApplicationsArray.map((item, index) => (
          <TabsContainer key={index}>
            {/* <Link to="/adp" state={{id: item.id}}><img src={item.image_url} /></Link> */}
            <img
              src={item.image_url}
              onClick={(name) =>
                VerificationProcess(item.name, item.download_url)
              }
            />
            {/* <label style={{width: '100%', height: "10%", fontSize: 25, fontWeight: "bold", color: "#000000", alignSelf: "center", borderColor: "#000000"}}>{item.name}</label> */}
          </TabsContainer>
        ))}
      </Gallery>
      {on_application_clicked === true ? (
        <Container>
          {is_application_available === true ? (
            <Heading>
              <a href={selected_application_link}>
                Download {selected_application_name}
              </a>
            </Heading>
          ) : (
            <Heading>
              <Link to={`/SignUp/${selected_application_name}`}>
                Register For {selected_application_name}
              </Link>
            </Heading>
          )}
        </Container>
      ) : null}
    </Container>
  );
};
