import React from "react";
import tw from "twin.macro";

import SliderLeiureTime from "../BeginnerHindi/SliderLeisureTimeThree";

const Container = tw.div` 2xl:mx-64 mx-20 my-4`;
const LeftContainer = tw.div`w-1/4 items-center`;
const RightContainer = tw.div`w-3/4 pl-16`;
const FlexContainer = tw.div`flex items-center`;

const Heading = tw.div`text-4xl text-center mb-8 font-black text-textprimary`;
const ListParagraph = tw.li` text-lg my-4 `;

export default () => {
  return (
    <Container>
      <Heading>ACE Worksheet – Hindi Level 1 & 2</Heading>
      <FlexContainer>
        <LeftContainer>
          <SliderLeiureTime />
        </LeftContainer>

        <RightContainer>
          <ListParagraph>
            Early Learning Interactive Activity Worksheets of International
            Standards for Beginner’s Hindi Level 1 & 2
          </ListParagraph>
          <ListParagraph>
            52 Pages each for Level 1 & 2 in Paperback
          </ListParagraph>
          <ListParagraph>
            Brand – ACE Worksheets – Popular in{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              2150 towns{" "}
            </span>{" "}
            in India
          </ListParagraph>
          <ListParagraph>Find & fix ‘learning-gaps’ in children</ListParagraph>
          <ListParagraph>
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Lakhs of satisfied Teachers{" "}
            </span>{" "}
            and happy Mothers
          </ListParagraph>
          <ListParagraph>
            Reinforces the entire basic skills, concepts & topics meant for
            Beginners Hindi
          </ListParagraph>
          <ListParagraph>
            Kids learn & understand extra concepts not covered in School
          </ListParagraph>
          <ListParagraph>
            Compelling topics, Appealing activities, Lovely layout; Thick paper;
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Colorful illustrations{" "}
            </span>
          </ListParagraph>
          <ListParagraph>
            Enhances the Child's imagination and enthusiasm
          </ListParagraph>
        </RightContainer>
      </FlexContainer>
    </Container>
  );
};
