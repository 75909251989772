import React from "react";
import tw from "twin.macro";

import SliderLeiureTime from "../PreKG/SliderLeisureTimeSix";

const Container = tw.div`flex items-center 2xl:mx-64 mx-20 my-4`;
const LeftContainer = tw.div`w-3/4 pr-16`;
const RightContainer = tw.div`w-1/4 `;

const SubHeading = tw.div`text-2xl italic my-4 text-blue-900 font-semibold`;
const Paragraph = tw.div`text-lg my-4 `;

const ListParagraph = tw.li` text-lg my-4 `;

export default () => {
  return (
    <Container>
      <LeftContainer>
        <SubHeading>
          216 Pages of Early Learning ACE Activity Worksheets
        </SubHeading>
        <Paragraph>
          Helps the Child to Know, Understand, Learn and to Adjust to the new
          eco-system.
        </Paragraph>
        <ListParagraph>
          These worksheets convert the Pre-KG / Nursery stage into
          <span
            style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
          >
            {" "}
            206 virtual steps;
          </span>
        </ListParagraph>
        <ListParagraph>
          Children will love to learn new concepts every day automatically by{" "}
          <span
            style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
          >
            DOING!
          </span>
        </ListParagraph>
        <ListParagraph>
          Once finished, they are ready to enter their Lower Kindergarten with
          loads of enthusiasm and confidence.
        </ListParagraph>
        <Paragraph>
          1.
          <span
            style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
          >
            {" "}
            Coverage:
          </span>{" "}
          Hand printing, Finger printing, Thumb impressions, Pattern formations,
          Matching, Scribbling, Shapes, Colors, Cloths, Toys, Lines, Curves,
          Alphabets, Paper tearing & pasting, Nature, Animals, Birds, Tracing,
          Fruits, Vegetables, Onion Print, Capsicum printing, Safety, People at
          work, Vehicles, Numbers, Good habits etc <br /> <br />
          2. Thousands of Teachers across India have made their Students use
          these colorful ACE worksheets in the last 10 years and are satisfied
          with
          <span
            style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
          >
            {" "}
            its comprehensive coverage and simplicity.
          </span>{" "}
          <br /> <br />
          3. Over a thousand School Managements across India will vouch for its
          <span
            style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
          >
            {" "}
            Cost-effectiveness.
          </span>{" "}
          <br /> <br />
          4.
          <span
            style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
          >
            {" "}
            Productively engage the Children
          </span>{" "}
          even during long holidays and during summer vacations - to re-cap the
          concepts! <br /> <br />
          5. Of late, thousands of Mothers have now started using these ACE
          Worksheets as return-gifts. <br /> <br />
          6. These ACE Worksheets are
          <span
            style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
          >
            {" "}
            available in the form of books as well as loose sheets.
          </span>{" "}
          <br /> <br />
        </Paragraph>
      </LeftContainer>

      <RightContainer>
        <SliderLeiureTime />
      </RightContainer>
    </Container>
  );
};
