import React from "react";
import tw from "twin.macro";

import TabsOne from "../Components/TabsOne";
import LeisureTime from "../Components/LeisureTime";
import homeart3 from "../Images/homeart3.png";
import CounterComponent from "../Components/CounterComponent";
const Container = tw.div` font-comicsans`;

const FlexContainer = tw.div`flex md:px-40 px-4 2xl:px-0 justify-center bg-welcomecolor`;
const FillerContainer = tw.div`h-40 md:-mb-80 -mb-32 bg-welcomecolor`;
const BannerContainer = tw.div``;
const Seperator = tw.div` h-1 my-8`;

export default () => {
  return (
    <Container>
      <Seperator />

      <LeisureTime />

      <CounterComponent />

      {/* <TabsAboutUs /> */}
      <TabsOne />

      {/* <OurSponsors /> */}

      <BannerContainer>
        <img src={homeart3} />
      </BannerContainer>
      <FlexContainer>
        <video controls width="1200">
          <source src="/Videos/center.mp4" type="video/mp4" />
        </video>
      </FlexContainer>

      <FillerContainer></FillerContainer>
    </Container>
  );
};
