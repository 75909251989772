/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";


import { ImLocation } from "react-icons/im";
import { GiRotaryPhone } from "react-icons/gi";
import { MdPhoneIphone, MdEmail } from "react-icons/md";

const Container = tw.div` 2xl:mx-64 md:mx-20 mx-8 font-comicsans md:-mb-28`;

const FlexContainer = tw.div`md:flex md:py-16`;
const LeftContainer = tw.div`md:w-6/12`;
const RightContainer = tw.div`md:w-6/12`;
const FormContainer = tw.div`md:flex md:py-4 justify-between `;
const SubmitButtonContainer = tw.div`mt-12 flex justify-center`;

const TitleContainer = tw.div`md:mx-20 mb-10`;
const DescriptionContainer = tw.div`md:mx-20 mt-14`;

const IconContainer = tw.div`mr-4`;
const TabContainer = tw.div`flex my-4 `;

const Heading = tw.div`md:text-4xl text-xl my-8 font-black text-blue-900`;
const SubHeading = tw.div`text-lg opacity-70`;


const SubmitButton = styled.input.attrs({
  type: "submit",
  value: "Submit",
})`
  background: orange;
  color: #fff;
  cursor: pointer;
  padding-left: 32px;
  padding-right: 32px;
  margin-bottom: 0;
  text-transform: uppercase;
  border-radius: 20px;
  height: 48px;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  &:active {
    background-color: #f1ac15;
  }
`;

const Input = styled.input`
  font-size: 18px;
  //  background: #d1d5db;  INPUT FIELD BACKGROUND COLLOR
  padding: 12px;
  margin-right: 30px;
  margin-bottom: 10px;
  width: 100%;
  border: groove;
  border-color: #cbcdd1;
  border-radius: 20px;
  ::placeholder {
    color: #b3b6ba;
  }
`;

const MessageInput = styled.input`
  font-size: 18px;
  // background: #d1d5db; MESSAGE INPUT FIELD BACKGROUND COLLOR
  padding: 12px;
  margin-right: 30px;
  width: 100%;
  resize: none;
  border: groove;
  border-color: #cbcdd1;
  border-radius: 20px;
  ::placeholder {
    color: #b3b6ba;
  }
`;

export default () => {
  return (
    <>
      {/* <GoogleMaps /> */}

      <Container>
        <FlexContainer>
          <LeftContainer>
            <Heading>We'd Like to Hear From You!</Heading>

            <FormContainer>
              <Input type="text" placeholder="Name" />
              <Input type="text" placeholder="Email" />
            </FormContainer>

            <FormContainer>
              <Input type="text" placeholder="Phone" />
              <Input type="text" placeholder="Subject" />
            </FormContainer>

            <FormContainer>
              <MessageInput type="text" placeholder="Message" />
            </FormContainer>
            <SubmitButtonContainer>
              <SubmitButton />
            </SubmitButtonContainer>
          </LeftContainer>

          <RightContainer>
            <TitleContainer>
              <Heading>Contact Info</Heading>
            </TitleContainer>

            <DescriptionContainer>
              <TabContainer>
                <IconContainer>
                  <ImLocation size="32" />
                </IconContainer>
                <SubHeading>
                  3H Learning Private Limited <br /> "Dhanalakshmi Nivas" <br />{" "}
                  No: 37/77,T.P.Koil 1st Lane Ground Floor Triplicane
                  Chennai-600 005
                </SubHeading>
              </TabContainer>

              <TabContainer>
                <IconContainer>
                  <MdPhoneIphone size="32" />
                </IconContainer>
                <SubHeading>080561 81204 &nbsp; &nbsp;</SubHeading>
              </TabContainer>

              <TabContainer>
                <IconContainer>
                  <GiRotaryPhone size="32" />
                </IconContainer>
                <SubHeading>
                  044-4556 4344&nbsp;&nbsp; | &nbsp;044-4857 4344
                </SubHeading>
              </TabContainer>

              <TabContainer>
                <IconContainer>
                  <MdEmail size="32" />
                </IconContainer>
                <SubHeading>3hl@3hlearning.com</SubHeading>
              </TabContainer>
            </DescriptionContainer>
          </RightContainer>
        </FlexContainer>
      </Container>

      {/* <MapContainer>
        <iframe
          width="100%"
          height="600"
          src="https://www.google.com/maps/place/3H+LEARNING/@13.0530431,80.2750303,17z/data=!3m1!4b1!4m5!3m4!1s0x3a52672acaba85b7:0x4482ea39c6b673cc!8m2!3d13.0530396!4d80.2764911"
        />
      </MapContainer> */}
    </>
  );
};
