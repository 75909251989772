/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import tw from "twin.macro";

import Applications from "../Components/Applications.js";
import Products from "../Components/Products.js";
import Testimonial from "../Components/Testimonial";
import { auth } from "../Components/FirebaseInit.js";
import { onAuthStateChanged } from "firebase/auth";

import OurApplicationNew from "./OurApplicationsNew";

const Container = tw.div` font-comicsans`;
const Seperator = tw.div` h-1 my-20 bg-sub`;
const Heading = tw.div`text-4xl my-8 text-center font-black text-textprimary`;
export default () => {
  const [signed_in, set_signed_in] = useState();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      user === null ? set_signed_in(false) : set_signed_in(true);
    });
  }, []);
  return (
    <>
      {signed_in === true ? (
        <Container>
          <OurApplicationNew />

          {/* <Products /> 

          <Applications />

          <Testimonial />*/}
        </Container>
      ) : (
        <Container>
          <Heading>
            <Link to="/SignIn">Redirect To SignIn!!</Link>
          </Heading>
        </Container>
      )}
    </>
  );
};
