import React from "react";
import tw from "twin.macro";

import art1 from "../../Images/homeart1.png";
import art2 from "../../Images/homeart2.png";
import dino2 from "../../Images/dino2.png";
import homecloud from "../../Images/homecloud.png";

import SliderLeiureTime from "../LKG/SliderLeisureTimeThree";

const SectionContainer = tw.div`bg-homeart 2xl:px-64 px-20  flex items-center`;
const ImageContainer = tw.div`flex justify-center -mt-1 `;
const DinoImageContainer = tw.div`flex justify-end transform -translate-x-40 2xl:translate-y-20 translate-y-16 2xl:mr-80 mr-28`;
const CloudImageContainer = tw.div`flex justify-end 2xl:mr-80 mr-20 2xl:-mt-40 -mt-32`;

const Heading = tw.div`text-2xl my-1 font-bold text-white`;
const ListParagraph = tw.li` pl-4 my-4 `;

const Container = tw.div``;

const LeftContainer = tw.div`w-1/4 `;
const RightContainer = tw.div`w-3/4 ml-32`;

export default () => {
  return (
    <Container>
      <DinoImageContainer>
        <img src={dino2} />
      </DinoImageContainer>

      <ImageContainer>
        <img src={art1} />
      </ImageContainer>

      <SectionContainer>
        <LeftContainer>
          <SliderLeiureTime />
        </LeftContainer>

        <RightContainer>
          <Heading>
            English, Mathematics, EVS / Gk & Coloring - 284 Pages
          </Heading>
          <ListParagraph>
            Early Learning Interactive Activity Worksheets of{" "}
            <span style={{ color: "#1E3A8A", fontWeight: "bold" }}>
              International{" "}
            </span>
            Standards with Indian Context for Lower Kindergarten / Pre-Primary 1
          </ListParagraph>
          <ListParagraph>
            Brand – ACE Worksheets – Popular in{" "}
            <span style={{ color: "#1E3A8A", fontWeight: "bold" }}>2150 </span>{" "}
            towns in India
          </ListParagraph>
          <ListParagraph>
            English (132 Pages), Mathematics (84 Pages) & General Knowledge &
            Coloring (68 Pages)
          </ListParagraph>
          <ListParagraph>
            Also available as loose sheets with file and pouch!
          </ListParagraph>
          <ListParagraph>
            Find & fix{" "}
            <span style={{ color: "#1E3A8A", fontWeight: "bold" }}>
              {" "}
              ‘learning-gaps’{" "}
            </span>{" "}
            in children
          </ListParagraph>
          <ListParagraph>
            Lakhs of satisfied Teachers and happy Mothers
          </ListParagraph>
          <ListParagraph>
            Reinforces the entire basic skills, concepts & topics meant for LKG
          </ListParagraph>
          <ListParagraph>
            Kids learn & understand extra concepts not covered in School
          </ListParagraph>
          <ListParagraph>
            Compelling topics, Appealing activities, Lovely layout;
          </ListParagraph>
          <ListParagraph>
            Thick paper;{" "}
            <span style={{ color: "#1E3A8A", fontWeight: "bold" }}>
              {" "}
              Colorful illustrations{" "}
            </span>{" "}
          </ListParagraph>
          <ListParagraph>
            Enhances the Child's imagination and enthusiasm
          </ListParagraph>
        </RightContainer>
      </SectionContainer>

      <ImageContainer>
        <img src={art2} />
      </ImageContainer>

      <CloudImageContainer>
        <img src={homecloud} width="20%" />
      </CloudImageContainer>
    </Container>
  );
};
