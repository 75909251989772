import React, { useState, useEffect } from "react";
import tw from "twin.macro";

import HeaderLogo from "../Images/logo.png";
import { auth } from "../Components/FirebaseInit.js";
import { onAuthStateChanged, signOut } from "firebase/auth";

const PrimaryLink = tw.a`pb-1 2xl:text-lg text-lg cursor-pointer  border-b border-transparent hover:border-yellow-400 hover:text-textprimary transition duration-300`;

const NavIcon = tw.div`text-black text-3xl absolute right-4 top-6 cursor-pointer md:hidden`;
const NavButtonCollection = tw.div`md:flex relative hidden`;

const TopContainer = tw.div`bg-white  font-comicsans items-center md:flex 2xl:justify-end 2xl:mr-40 py-3`;
const BottomContainer = tw.div`flex`;

const ColorOne = tw.div`h-1 w-1/6 bg-gray-200`;
const ColorTwo = tw.div`h-1 w-1/6 bg-gray-200`;
const ColorThree = tw.div`h-1 w-1/6 bg-yellow-400`;
const ColorFour = tw.div`h-1 w-1/6 bg-blue-300`;
const ColorFive = tw.div`h-1 w-1/6 bg-green-200`;
const ColorSix = tw.div`h-1 w-1/6 bg-red-500`;
const ColorSeven = tw.div`h-1 w-1/6 bg-yellow-200`;
const ColorEight = tw.div`h-1 w-1/6 bg-blue-400`;
const ColorNine = tw.div`h-1 w-1/6 bg-red-200`;
const ColorTen = tw.div`h-1 w-1/6 bg-yellow-300`;
const ColorEleven = tw.div`h-1 w-1/6 bg-gray-200`;
const ColorTwelve = tw.div`h-1 w-1/6 bg-gray-200`;

const LeftContainer = tw.div``;
const RightContainer = tw.div`flex justify-end mr-20 md:mr-0`;

// const GradesContainer = tw.div`md:bg-white z-10 py-1 px-2 border text-lg md:absolute 2xl:right-GradesNew right-Gradesmd  top-12 bg-gray-100 ml-14 mr-64 md:mr-0`;
// const LanguagesContainer = tw.div`md:bg-white z-10 py-1 px-2 border text-lg md:absolute  2xl:right-Languages right-96 top-12 bg-gray-100 ml-14 mr-32 md:mr-0 `;

const GradesContainer = tw.div`md:bg-white z-10 py-1 px-2 border text-lg md:absolute 2xl:right-GradesLast right-Grades42  top-12 bg-gray-100 ml-14 mr-64 md:mr-0`;
const LanguagesContainer = tw.div`md:bg-white z-10 py-1 px-2 border text-lg md:absolute  2xl:right-Grades right-Gradesmd top-12 bg-gray-100 ml-14 mr-32 md:mr-0 `;
const MultipleContainer = tw.a`block hocus:text-textprimary py-1`;

const NavButton = tw.div`  md:my-4 2xl:ml-10 
my-4 ml-8`;
const HeaderLogoContainer = tw.div`2xl:mr-40  md:w-64 `;
const FlexLogoContainer = tw.div` mr-48 md:mr-0`;
const ImageLink = tw.a``;

export default () => {
  let [open_nav, setOpenNav] = useState(false);
  let [open, setOpen] = useState(false);
  let [open_lang, setOpenLang] = useState(false);
  const [signed_in, set_signed_in] = useState(false);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      user === null ? set_signed_in(false) : set_signed_in(true);
    });
  }, []);
  const OnSignOutClicked = () => {
    signOut(auth)
      .then(() => {
        set_signed_in(false);
      })
      .catch((error) => {
        alert(error);
        // An error happened.
      });
  };
  const change_language_bar = () => {
    setOpenLang(!open_lang);
    setOpen(false);
  };
  const change_grade_bar = () => {
    setOpen(!open);
    setOpenLang(false);
  };
  return (
    <>
      <TopContainer>
        <HeaderLogoContainer>
          <FlexLogoContainer>
            <ImageLink href="/">
              <img src={HeaderLogo} />
            </ImageLink>
          </FlexLogoContainer>
        </HeaderLogoContainer>

        <NavIcon onClick={() => setOpenNav(!open_nav)}>
          <ion-icon name={open_nav ? "close" : "menu"}></ion-icon>
        </NavIcon>

        <LeftContainer>
          <NavButtonCollection>
            {/* LeftContainer */}
            <NavButton>
              <PrimaryLink href="/">Home</PrimaryLink>
            </NavButton>

            <NavButton>
              <PrimaryLink href="/AboutUs">About Us & Our Brands </PrimaryLink>
            </NavButton>

            <NavButton>
              <PrimaryLink onClick={() => change_grade_bar()}>
                Grades
              </PrimaryLink>
            </NavButton>
            {open === true ? (
              <GradesContainer>
                <MultipleContainer href="/PreKG">PreKG</MultipleContainer>
                <MultipleContainer href="/LKG">LKG</MultipleContainer>
                <MultipleContainer href="/UKG">UKG</MultipleContainer>
                <MultipleContainer href="/STD1">STD1</MultipleContainer>
              </GradesContainer>
            ) : null}

            <NavButton>
              <PrimaryLink
                onClick={() => {
                  change_language_bar();
                }}
              >
                Languages
              </PrimaryLink>
            </NavButton>

            {open_lang === true ? (
              <LanguagesContainer>
                <MultipleContainer href="/BeginnersHindi">
                  Beginners Hindi
                </MultipleContainer>
                <MultipleContainer href="/BeginnersTamil">
                  Beginners Tamil
                </MultipleContainer>
                <MultipleContainer href="/EnglishCursive">
                  English Cursive
                </MultipleContainer>
              </LanguagesContainer>
            ) : null}

            <NavButton>
              {/* href="/OurApplication" */}
              <PrimaryLink href="/OurApplicationsNew">
                Our Applications
              </PrimaryLink>
            </NavButton>

            <NavButton>
              <PrimaryLink href="/DialogueBooster">
                Dialogue Booster
              </PrimaryLink>
            </NavButton>

            <NavButton>
              <PrimaryLink href="/ContactUs">Contact Us</PrimaryLink>
            </NavButton>
            {signed_in === false ? (
              <NavButton>
                {/* href="/SignIn" */}
                <PrimaryLink href="/SignIn">Sign In</PrimaryLink>
              </NavButton>
            ) : (
              <NavButton>
                {/* onClick={() => OnSignOutClicked()} */}

                <PrimaryLink onClick={() => OnSignOutClicked()}>
                  Signout
                </PrimaryLink>
              </NavButton>
            )}
          </NavButtonCollection>

          {open_nav === true ? (
            <>
              <NavButton>
                <PrimaryLink href="/">Home</PrimaryLink>
              </NavButton>

              <NavButton>
                <PrimaryLink href="/AboutUs">
                  About Us & Our Brands{" "}
                </PrimaryLink>
              </NavButton>

              <NavButton>
                <PrimaryLink onClick={() => change_grade_bar()}>
                  Grades
                </PrimaryLink>
              </NavButton>
              {open === true ? (
                <GradesContainer>
                  <MultipleContainer href="/PreKG">PreKG</MultipleContainer>
                  <MultipleContainer href="/LKG">LKG</MultipleContainer>
                  <MultipleContainer href="/UKG">UKG</MultipleContainer>
                  <MultipleContainer href="/STD1">STD1</MultipleContainer>
                </GradesContainer>
              ) : null}

              <NavButton>
                <PrimaryLink
                  onClick={() => {
                    change_language_bar();
                  }}
                >
                  Languages
                </PrimaryLink>
              </NavButton>

              {open_lang === true ? (
                <LanguagesContainer>
                  <MultipleContainer href="/BeginnersHindi">
                    Beginners Hindi
                  </MultipleContainer>
                  <MultipleContainer href="/BeginnersTamil">
                    Beginners Tamil
                  </MultipleContainer>
                  <MultipleContainer href="/EnglishCursive">
                    English Cursive
                  </MultipleContainer>
                </LanguagesContainer>
              ) : null}

              <NavButton>
                {/* href="/OurApplication" */}
                <PrimaryLink href="/OurApplicationsNew">
                  Our Applications
                </PrimaryLink>
              </NavButton>

              <NavButton>
                <PrimaryLink href="/ContactUs">Contact Us</PrimaryLink>
              </NavButton>
              {signed_in === false ? (
                <NavButton>
                  {/* href="/SignIn" */}
                  <PrimaryLink href="/SignIn">Sign In</PrimaryLink>
                </NavButton>
              ) : (
                <NavButton>
                  {/* onClick={() => OnSignOutClicked()} */}

                  <PrimaryLink onClick={() => OnSignOutClicked()}>
                    Signout
                  </PrimaryLink>
                </NavButton>
              )}
            </>
          ) : null}
        </LeftContainer>
      </TopContainer>

      <BottomContainer>
        <ColorOne></ColorOne>
        <ColorTwo></ColorTwo>
        <ColorThree></ColorThree>
        <ColorFour></ColorFour>
        <ColorFive></ColorFive>
        <ColorSix></ColorSix>
        <ColorSeven></ColorSeven>
        <ColorEight></ColorEight>
        <ColorNine></ColorNine>
        <ColorTen></ColorTen>
        <ColorEleven></ColorEleven>
        <ColorTwelve></ColorTwelve>
      </BottomContainer>
    </>
  );
};
