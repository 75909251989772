import React from "react";
import tw from "twin.macro";

import SliderLeiureTime from "../PreKG/SliderLeisureTimeFour";

const Container = tw.div`flex items-center 2xl:mx-64 mx-20 my-4`;
const LeftContainer = tw.div`w-3/4 pr-16`;
const RightContainer = tw.div`w-1/4 pl-16`;

const SubHeading = tw.div`text-xl italic my-4 text-textprimary font-semibold`;
const Heading = tw.div`text-4xl mb-8 font-black text-textprimary`;
const Paragraph = tw.div`text-lg my-4 `;

export default () => {
  return (
    <Container>
      <LeftContainer>
        <Heading>Learning Progress Set (LPS)</Heading>
        <SubHeading>Primary Objective: </SubHeading>
        <Paragraph>
          Every Parent loves seeing the progress-in-learning of their ward.
          Learning Progress Set (LPS) captures this.{" "}
          <span
            style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
          >
            {" "}
            A set of LPS (loose sheets) for each Child is issued to the School.
          </span>{" "}
          The Teacher gets to make the Child finish one-sheet-a-day and sends
          them for the Parents to see. Parents are thrilled to see colorful
          sheets sent everyday by the School!
        </Paragraph>
        <Paragraph>
          Alternatively, the School may even choose to send these sheets home to
          be done by the Child in front of the Parents. To send home or get them
          done at Schools is a choice decided by the School based on local
          conditions.
          <span
            style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
          >
            {" "}
            Either way, Parents will be delighted to see the progress of their
            wards.
          </span>{" "}
          Ideally, during an Academic year, the School can start sending these
          sheets from 15th of January onwards
        </Paragraph>
      </LeftContainer>

      <RightContainer>
        <SliderLeiureTime />
      </RightContainer>
    </Container>
  );
};
