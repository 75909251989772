import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import backgroundseperator from "../Images/backgroundseperator.png";
import { auth } from "../Components/FirebaseInit.js";
import {
  onAuthStateChanged,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
const Container = tw.div`md:mx-64 my-20 font-comicsans`;
const FormContainer = tw.div`flex justify-center py-4  `;
const SubmitButtonContainer = tw.div` flex justify-center`;
const SignInContainer = tw.div`text-center text-sm placeholder-opacity-70 hocus:text-blue-500 m-2`;

const Heading = tw.div`md:text-4xl text-2xl my-8 text-center font-black text-textprimary`;
const SubHeading = tw.div`text-base opacity-70`;
const FlexContainer = tw.div`flex justify-center`;
const BackgroundImage = tw.div``;

const SubmitButton = styled.input.attrs({
  type: "submit",
  value: "Get OTP",
  id: "sign-in-button",
})`
  background: orange;
  color: #fff;
  cursor: pointer;
  margin-bottom: 0;
  text-transform: uppercase;
  border-radius: 5px;
  height: 35px;
  width: 20%;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  &:active {
    background-color: #f1ac15;
  }
`;
const EnterOTPButton = styled.input.attrs({
  type: "submit",
  value: "Enter OTP",
})`
  background: orange;
  color: #fff;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 0;
  text-transform: uppercase;
  border-radius: 5px;
  height: 35px;
  width: 20%;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  &:active {
    background-color: #f1ac15;
  }
`;
const Input = styled.input`
  font-size: 18px;
  background: #e4e4e7; // INPUT FIELD BACKGROUND COLLOR
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 30%;
  ::placeholder {
    color: palevioletred;
  }
`;
const PrePhoneText = styled.h1`
  font-size: 18px;
  background: #e4e4e7; // INPUT FIELD BACKGROUND COLLOR
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 5px;
  cursor: default;
`;

const SignIn = () => {
  const [signed_in, set_signed_in] = useState(false);
  const [phone_number, set_phone_number] = useState("");
  const onChangePhoneNumber = (e) => {
    set_phone_number(e.target.value);
  };
  const [otp_received, set_otp_received] = useState(false);
  const [otp, set_otp] = useState("");
  const onChangeOTP = (e) => {
    set_otp(e.target.value);
  };
  const [otp_entered, set_otp_entered] = useState(false);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      user === null ? set_signed_in(false) : set_signed_in(true);
    });
  }, []);
  const GetOTP = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, "+91".concat(phone_number), appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        set_otp_received(true);
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        alert(error);
      });
  };
  const EnterOtp = () => {
    window.confirmationResult
      .confirm(otp)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        set_signed_in(true);
        set_otp_received(false);
        // ...
      })
      .catch((error) => {
        alert(error);
        // User couldn't sign in (bad verification code?)
        // ...
      });
  };
  return (
    <>
      {signed_in === false ? (
        <>
          <Container>
            <Heading>Log In to 3H Learning</Heading>
            {otp_received === false ? (
              <>
                <FormContainer>
                  <PrePhoneText>+91</PrePhoneText>
                  <Input
                    type="text"
                    placeholder="Phone Number To Get OTP"
                    value={phone_number}
                    onChange={(e) => onChangePhoneNumber(e)}
                  />
                </FormContainer>

                <SubmitButtonContainer>
                  <SubmitButton onClick={() => GetOTP()} />
                </SubmitButtonContainer>
              </>
            ) : (
              <>
                <FormContainer>
                  <Input
                    type="text"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => onChangeOTP(e)}
                  />
                </FormContainer>

                <SubmitButtonContainer>
                  <EnterOTPButton onClick={() => EnterOtp()} />
                </SubmitButtonContainer>
              </>
            )}
          </Container>

          <BackgroundImage>
            <img src={backgroundseperator} />
          </BackgroundImage>
        </>
      ) : (
        <Container>
          <Heading>Already Signed In!!</Heading>
        </Container>
      )}
    </>
  );
};
export default SignIn;
