/* eslint-disable no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { auth } from "../Components/FirebaseInit.js";
// import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { db } from "../Components/FirebaseInit.js";
import { doc, setDoc } from "firebase/firestore";
import { Link } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";

const Container = tw.div`mx-64 my-10 font-comicsans`;
const FormContainer = tw.div`flex justify-center py-4  `;
const SubmitButtonContainer = tw.div`mt-4 flex justify-center`;

const Heading = tw.div`text-4xl my-8 text-center font-black text-textprimary`;

const SubmitButton = styled.input.attrs({
  type: "submit",
  value: "Sign Up",
  id: "sign-in-button",
})`
  background: orange;
  color: #fff;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 0;
  text-transform: uppercase;
  border-radius: 5px;
  height: 35px;
  width: 20%;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  &:active {
    background-color: #f1ac15;
  }
`;

const Input = styled.input`
  font-size: 18px;
  background: #e4e4e7; // INPUT FIELD BACKGROUND COLLOR
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 30%;
  ::placeholder {
    color: palevioletred;
  }
`;

export default () => {
  const { testvalue } = useParams();
  // const [userName, setUserName] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [grade, setGrade] = useState("");
  // const [school, setSchool] = useState("");
  // const [city, setCity] = useState("");
  // const [ _ConfirmationResult, setConfirmationResult] = useState();
  // const [Otp, setOtp] = useState("");
  // const [OtpRecieved, setOtpRecieved] = useState(false);
  // const [OtpEntered, setOtpEntered] = useState(false);
  // const onChangeUserName = (e) => {
  // setUserName(e.target.value);
  // }
  // const onChangePhoneNumber = (e) => {
  // setPhoneNumber(e.target.value);
  // }
  // const onChangeGrade = (e) => {
  // setGrade(e.target.value);
  // }
  // const onChangeSchool = (e) => {
  // setSchool(e.target.value);
  // }
  // const onChangeCity = (e) => {
  // setCity(e.target.value);
  // }
  // const GetOtp = () => {

  //   alert('Please')
  // if (userName.length !==0 && phoneNumber.length !==0 && grade.length !==0 && school.length !==0 && city.length !==0 )
  // {
  //   window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
  //     'size': 'invisible',
  //     'callback': (response) => {
  //       alert(response);
  //       setOtpRecieved(true);
  //       // // reCAPTCHA solved, allow signInWithPhoneNumber.
  //       // const appVerifier = window.recaptchaVerifier;
  //       // signInWithPhoneNumber(auth, phoneNumber, appVerifier)
  //       // .then((confirmationResult) => {
  //       //   // SMS sent. Prompt user to type the code from the message, then sign the
  //       //   // user in with confirmationResult.confirm(code).
  //       //   window.confirmationResult = confirmationResult;
  //       //   setConfirmationResult(confirmationResult);
  //       //
  //       //   // ...
  //       // }).catch((error) => {
  //       //   // Error; SMS not sent
  //       //   // ...
  //       // });
  //     }},auth);
  // //window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);
  // }
  // }
  // const EnterOtp = () => {
  //   _ConfirmationResult.confirm(Otp).then((result) => {
  //     // User signed in successfully.
  //     const user = result.user;
  //     // ...
  //   }).catch((error) => {
  //     // User couldn't sign in (bad verification code?)
  //     // ...
  //   });
  // }
  const [password, set_password] = useState("");
  const onChangePassword = (e) => {
    set_password(e.target.value);
  };
  const [confirm_password, set_confirm_password] = useState("");
  const onChangeConfirmPassword = (e) => {
    set_confirm_password(e.target.value);
  };
  const [registered, set_registered] = useState(false);
  const onClickedResgister = () => {
    if (
      password.length > 0 &&
      confirm_password.length > 0 &&
      password === confirm_password
    ) {
      RegisterUser();
    }
  };
  async function RegisterUser() {
    await setDoc(doc(db, testvalue, auth.currentUser.phoneNumber.slice(1)), {
      user_mobile: auth.currentUser.phoneNumber,
      password: password,
      mac_address: "",
    });
    set_password("");
    set_confirm_password("");
    set_registered(true);
  }
  return (
    <Container>
      {registered === false ? (
        <>
          <Heading>Register For {testvalue}</Heading>
          <FormContainer>
            <Input
              type="text"
              placeholder="User Mobile Number"
              value={auth.currentUser.phoneNumber}
            />
          </FormContainer>
          <FormContainer>
            <Input
              type="password"
              placeholder="Password"
              onChange={(e) => onChangePassword(e)}
            />
          </FormContainer>

          <FormContainer>
            <Input
              type="password"
              placeholder="Confirm Password"
              onChange={(e) => onChangeConfirmPassword(e)}
            />
          </FormContainer>
          <SubmitButtonContainer>
            <SubmitButton onClick={() => RegisterUser()} />
          </SubmitButtonContainer>
        </>
      ) : (
        <>
          <Heading>Registration Successfull</Heading>
          <Heading>
            <Link to="/OurApplication">Back To Applications</Link>
          </Heading>
        </>
      )}
      {/* {OtpRecieved===false?
<>
      <FormContainer>
        <Input type="text" placeholder="Name" onChange={(e) => onChangeUserName(e) } />
      </FormContainer>

      <FormContainer>
        <Input type="text" placeholder="Phone Number" onChange={(e) => onChangePhoneNumber(e) } />
      </FormContainer>

      <FormContainer>
        <Input type="text" placeholder="School Name" onChange={(e) => onChangeGrade(e) } />
      </FormContainer>

      <FormContainer>
        <Input type="text" placeholder="Grade" onChange={(e) => onChangeSchool(e) } />
      </FormContainer>

      <FormContainer>
        <Input type="text" placeholder="City" onChange={(e) => onChangeCity(e) } />
      </FormContainer>

      <SubmitButtonContainer><SubmitButton /></SubmitButtonContainer>

      </>: 
      <>

      <FormContainer>
        <Input type="text" placeholder="OTP" onChange={(e) => onChangePhoneNumber(e) } />
      </FormContainer>

<SubmitButtonContainer><SubmitButton /></SubmitButtonContainer>
</>}
      <a href="SignIn"><SignInContainer>Already have an account?</SignInContainer></a> */}
    </Container>
  );
};
