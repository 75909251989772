import React from "react";
import tw from "twin.macro";

import SliderLeiureTime from "../BeginnerTamil/SliderLeisureTimeThree";
import SliderLeiureTimeNew from "../BeginnerTamil/SliderLeisureTimeThreeNew";

const Container = tw.div` 2xl:mx-64 mx-20 my-4`;
const LeftContainer = tw.div`w-3/4 pr-16`;
const RightContainer = tw.div`w-1/4`;

const SecondLeftContainer = tw.div`w-1/4 `;
const SecondRightContainer = tw.div`w-3/4 pl-16 flex justify-end`;
const FlexContainer = tw.div`flex items-center`;

const SubHeading = tw.div`text-xl italic my-4 text-textprimary font-semibold`;
const Heading = tw.div`text-4xl text-center mb-8 font-black text-textprimary`;
const ListParagraph = tw.li` text-lg my-4 `;

export default () => {
  return (
    <Container>
      <Heading>ACE Worksheet – Tamil Level 1 & 2</Heading>
      <FlexContainer>
        <LeftContainer>
          <ListParagraph>
            Early Learning Interactive Activity Worksheets of International
            Standards for Beginner’s Tamil Level 1 & 2
          </ListParagraph>
          <ListParagraph>
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Comprehensive Content,{" "}
            </span>{" "}
            Methodology and Gold Mine of Activities
          </ListParagraph>
          <ListParagraph>
            Novel way to introduce concepts & learning in Children
          </ListParagraph>
          <ListParagraph>
            Reinforces the entire basic skills, concepts & topics meant for
            Beginners Tamil
          </ListParagraph>
          <ListParagraph>
            Compelling topics, Appealing activities, Lovely layout; Thick paper;
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Colorful illustrations{" "}
            </span>{" "}
            makes each page enjoyable
          </ListParagraph>
          <ListParagraph>
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Easy explanations{" "}
            </span>{" "}
            holds any Child’s attention and makes learning simple, fund &
            enjoyable
          </ListParagraph>
          <ListParagraph>
            84 Pages each for Level 1 & 2 in Paperback
          </ListParagraph>
          <ListParagraph>
            Every page has an Objective and Learning Outcome
          </ListParagraph>
          <ListParagraph>
            Brand – ACE Worksheets – Popular in 2150 towns in India
          </ListParagraph>
          <ListParagraph>
            Find & fix{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              ‘learning-gaps’
            </span>{" "}
            in children
          </ListParagraph>
          <ListParagraph>
            Lakhs of satisfied Teachers and happy Mothers
          </ListParagraph>
          <ListParagraph>
            Kids learn & understand extra concepts not covered in School
          </ListParagraph>
          <ListParagraph>
            Enhances the Child's imagination and enthusiasm
          </ListParagraph>
        </LeftContainer>

        <RightContainer>
          <SliderLeiureTime />
        </RightContainer>
      </FlexContainer>

      <FlexContainer>
        <SecondLeftContainer>
          <SliderLeiureTimeNew />
        </SecondLeftContainer>

        <SecondRightContainer>
          <div>
            <SubHeading>Benefits of Writing Practice</SubHeading>
            <ListParagraph>
              Starts from Strokes, Curves, lines etc & gradually moves up to
              Uyir Ezhuthukkal, Mei Exhuthukkal and even some Uyir Mei
              Ezhuthukkal
            </ListParagraph>
            <ListParagraph>
              Ample space & Creative design{" "}
              <span
                style={{
                  color: "#1E3A8A",
                  fontWeight: "bold",
                  fontSize: "105%",
                }}
              >
                {" "}
                help the child to practice each component completely{" "}
              </span>
            </ListParagraph>
            <ListParagraph>
              Space provided for customizing difficult items for additional
              practice
            </ListParagraph>
          </div>
        </SecondRightContainer>
      </FlexContainer>
    </Container>
  );
};
