import React from "react";
import tw from "twin.macro";

import SectionOne from "../Components/BeginnerHindi/SectionOne";
import SectionThree from "../Components/BeginnerHindi/SectionThree";
import SectionFour from "../Components/BeginnerHindi/SectionFour";
import SectionSix from "../Components/BeginnerHindi/SectionSix";

const Container = tw.div` font-comicsans`;

const Heading = tw.div`text-5xl text-center 2xl:mx-64 mx-20 my-24 font-black text-blue-900`;

export default () => {
  return (
    <Container>
      {/* ----------------------------------------------------------------*/}
      {/* ----------------------------------------------------------------*/}
      {/* ----------------------------------------------------------------*/}
      <Heading>Beginners Hindi</Heading>
      <SectionOne />
      <br />
      <br /> <br />
      <SectionThree />
      <br /> <br /> <br />
      <SectionFour />
      <br /> <br /> <br />
      <SectionSix />
    </Container>
  );
};
