import React from "react";
import tw from "twin.macro";

import SectionThree from "../Components/EnglishCursive/SectionThree";

const Container = tw.div` font-comicsans`;

const Heading = tw.div`text-5xl text-center 2xl:mx-64 mx-20 my-24 font-black text-blue-900`;

export default () => {
  return (
    <Container>
      <Heading>English Cursive Writing Books </Heading>
      <SectionThree />
    </Container>
  );
};
