import React, { useState, useEffect } from "react";
import tw from "twin.macro";

import ModalVideo from "react-modal-video";
import SliderLeiureTime from "./VocaSliderSolo";

import homeart3 from "../../Images/homeart3.png";
import logo from "../../Images/Voc/logo_1024.png";
import logoPlay from "../../Images/googleplay.png";
import logoWindows from "../../Images/windows.png";
import { auth } from "../FirebaseInit.js";
import {
  onAuthStateChanged,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { db } from "../FirebaseInit.js";
import { doc, setDoc, getDoc } from "firebase/firestore";
import styled from "styled-components";
const BannerContainer = tw.div`-mt-4`;

const ReactModalContainer = tw.div`fixed flex justify-center top-40 left-Grades w-full z-50`;

const Container = tw.div` font-comicsans`;
const SectionContainer = tw.div`md:flex mt-20 2xl:mx-64 md:mx-12 mx-8`;
const LeftContainer = tw.div`md:w-4/12  `;
const RightContainer = tw.div`md:w-8/12 pl-12 2xl:pl-16`;
const ImageContainer = tw.div`flex justify-center mr-24 ml-4 2xl:mr-16`;
const VideoContainer = tw.div` flex px-8 md:px-40 2xl:px-0 justify-center bg-welcomecolor`;

const FlexLogoContainer = tw.div`md:flex justify-center`;
const LogoLeftContainer = tw.a`md:w-4/12 flex items-center`;
const LogoRightContainer = tw.a`md:flex md:w-8/12 justify-start items-center ml-12 font-semibold text-xs hidden `;

const LogoContainer = tw.a`flex items-center w-48`;
const WindowsLogoContainer = tw.a` flex items-center w-80 cursor-pointer`;
const Price = tw.h1` m-4 p-2 text-xl flex items-center text-white bg-gray-800 rounded-lg`;
const Paragraph = tw.div`text-lg my-4 `;
const FlexParaContainer = tw.div` flex justify-center  my-8`;

const FlexContainer = tw.div`flex justify-center`;
const RecommendedContainer = tw.div`w-1/2 mt-4 text-lg`;
const MinimumContainer = tw.div`w-1/2 mt-4 text-lg`;

const RegistrationForm = tw.div``;
const NewButton = tw.button`bg-red-500 rounded`;

const SliderContainer = tw.div`bg-red-500 my-20`;
const SliderTopContainer = tw.div` `;
const FillerContainer = tw.div`h-40 md:-mb-80 -mb-32 bg-welcomecolor`;
const NewPrimaryButton = tw.a` 2xl:mx-8 mx-4 p-2 text-xl cursor-pointer items-center text-white bg-blue-900 rounded `;
const LoginHeading = tw.div`text-4xl my-4 text-center font-black text-textprimary`;
const Heading = tw.div`text-4xl my-8 text-center font-black text-textprimary`;
const FormContainer = tw.div`flex justify-center py-4  `;
const SubmitButtonContainer = tw.div` flex justify-center`;
const SignInContainer = tw.div`text-center text-sm placeholder-opacity-70 hocus:text-blue-500 m-2`;
const SubmitButton = styled.input.attrs({
  type: "submit",
  value: "Get OTP",
  id: "sign-in-button",
})`
  background: orange;
  color: #fff;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 0;
  text-transform: uppercase;
  border-radius: 5px;
  height: 35px;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  &:active {
    background-color: #f1ac15;
  }
`;
const EnterOTPButton = styled.input.attrs({
  type: "submit",
  value: "Enter OTP",
})`
  background: orange;
  color: #fff;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 0;
  text-transform: uppercase;
  border-radius: 5px;
  height: 35px;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  &:active {
    background-color: #f1ac15;
  }
`;
const Input = styled.input`
  font-size: 18px;
  background: #e4e4e7; // INPUT FIELD BACKGROUND COLLOR
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  ::placeholder {
    color: palevioletred;
  }
`;
const PrePhoneText = styled.h1`
  font-size: 18px;
  background: #e4e4e7; // INPUT FIELD BACKGROUND COLLOR
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 5px;
  cursor: default;
`;

const loda = () => {
  const script = document.createElement("script");

  script.src = "https://checkout.razorpay.com/v1/payment-button.js";
  script.async = true;
  script.id = "pl_Kfuz03aGsEQWyY";
};

export default () => {
  const [showButtons, set_showButtons] = useState(true);

  const [signed_in, set_signed_in] = useState(false);
  const [phone_number, set_phone_number] = useState("");
  const onChangePhoneNumber = (e) => {
    set_phone_number(e.target.value);
  };
  const [otp_received, set_otp_received] = useState(false);
  const [otp, set_otp] = useState("");
  const onChangeOTP = (e) => {
    set_otp(e.target.value);
  };
  const [otp_entered, set_otp_entered] = useState(false);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user === null) {
        set_signed_in(false);
      } else {
        set_signed_in(true);
        CheckPatreon();
      }
    });
  }, []);
  const GetOTP = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, "+91".concat(phone_number), appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        set_otp_received(true);
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        alert(error);
      });
  };
  const EnterOtp = () => {
    window.confirmationResult
      .confirm(otp)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        set_signed_in(true);
        CheckPatreon();
        // ...
      })
      .catch((error) => {
        alert(error);
        // User couldn't sign in (bad verification code?)
        // ...
      });
  };
  const [isOpen, setOpen] = useState(false);
  const [open_buying_modal, set_open_buying_modal] = useState(false);
  const [open_buy, set_open_buy] = useState(false);
  const CheckForAuth = () => {
    set_open_buying_modal(true);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        set_open_buy(true);
        CheckPatreon();
        // ...
      } else {
        set_open_buy(false);
      }
    });
  };
  async function CheckPatreon() {
    const docRef = doc(
      db,
      "VocaBuilderSolo",
      auth.currentUser.phoneNumber.slice(1)
    );
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      set_registered(true);
      console.log("Document data:", docSnap.data());
    } else {
      set_registered(false);
      console.log("No Data Found");
    }
  }
  // const close_opened_modal = () => {
  //   {
  //     if (open_buying_modal === true) {
  //       set_open_buying_modal(false);
  //     }
  //   }
  // };

  const [password, set_password] = useState("");
  const onChangePassword = (e) => {
    set_password(e.target.value);
  };
  const [confirm_password, set_confirm_password] = useState("");
  const onChangeConfirmPassword = (e) => {
    set_confirm_password(e.target.value);
  };
  const [registered, set_registered] = useState(false);
  const onClickedResgister = () => {
    if (
      password.length > 0 &&
      confirm_password.length > 0 &&
      password === confirm_password
    ) {
      TakePaymentMethod(2999);
    } else {
      alert("Fields Are Missing!!");
    }
  };
  async function RegisterUser() {
    await setDoc(
      doc(db, "VocaBuilderSolo", auth.currentUser.phoneNumber.slice(1)),
      {
        user_mobile: auth.currentUser.phoneNumber,
        password: password,
        mac_address: "",
      }
    );
    set_password("");
    set_confirm_password("");
    set_registered(true);
  }
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const TakePaymentMethod = async (amount) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("You are offline");
      return;
    }
    const options = {
      key: "rzp_live_y0qgHzZWQdI1UF",
      currency: "INR",
      amount: amount * 100,
      name: "Voca Builder Solo",
      desciption: "Voca Builder Solo",
      image: { logo },
      handler: function (response) {
        alert(response.razorpay_payment_id);
        if (response.razorpay_payment_id) {
          RegisterUser();
          // alert("Successfully Registered");
        } else {
          alert("Payment Failed!!");
        }
      },
      prefill: {
        name: "Three H Learning",
      },
    };
    const payment_object = new window.Razorpay(options);
    payment_object.open();
  };
  return (
    <Container
    //onClick={() => close_opened_modal()}
    >
      <SectionContainer>
        <LeftContainer>
          <ImageContainer>
            <img src={logo} width="100%" />
          </ImageContainer>
          <FlexParaContainer>
            <Paragraph>
              <span style={{ color: "#EE741D" }}>Highlights </span> of
              VocaBuilder - Solo App
              <br /> <br />• One-of-its-kind
              <span style={{ color: "#EE741D" }}>
                ‘Play & Learn – IntelliGame’
              </span>
              <br />
              • School / Teacher time not blocked <br />•
              <span style={{ color: "#EE741D" }}>20000 essential words</span>
              with meaning <br />
              • Software is both a ‘Coach’ as well as a ‘Competitor’ <br />
              • Child – ‘Challenged’ as well as ‘Guided’ <br />
              • Beautiful blend of ‘fun’ and ‘learning’ <br />• Convenient
              <span style={{ color: "#EE741D" }}>
                – Anywhere / Anytime / Any Pace
              </span>
              <br />
              • Learning @fingertip of your Child <br />•
              <span style={{ color: "#EE741D" }}> Dynamic </span> – Different
              outcomes every time <br />
              • Clutter free reports every time <br />•
              <span style={{ color: "#EE741D" }}>Clear & visible</span>
              improvement before and after usage <br />
              • Suitable for all types of Children and grown-ups <br />•
              <span style={{ color: "#EE741D" }}>Useful for many years</span>
              <br /> <br />
            </Paragraph>
          </FlexParaContainer>
        </LeftContainer>
        <RightContainer>
          {open_buying_modal === true ? (
            <>
              {open_buy === true ? (
                <SliderTopContainer>
                  {registered === false ? (
                    <RegistrationForm>
                      <Heading>Register For Voca Builder Solo</Heading>
                      <FormContainer>
                        <Input
                          type="text"
                          placeholder="User Mobile Number"
                          value={auth.currentUser.phoneNumber}
                        />
                      </FormContainer>
                      <FormContainer>
                        <Input
                          type="password"
                          placeholder="Password"
                          onChange={(e) => onChangePassword(e)}
                        />
                      </FormContainer>
                      <FormContainer>
                        <Input
                          type="password"
                          placeholder="Confirm Password"
                          onChange={(e) => onChangeConfirmPassword(e)}
                        />
                      </FormContainer>
                      <FormContainer>
                        <label for="checked">
                          <input
                            type="checkbox"
                            id="checked"
                            name="checked"
                            value="yes"
                          />{" "}
                          &nbsp; Click here to accept our Privacy Policy and
                          Terms & Conditions
                        </label>
                      </FormContainer>
                      <FormContainer>
                        <NewPrimaryButton onClick={() => onClickedResgister()}>
                          Register Now! @ ₹2999
                        </NewPrimaryButton>
                      </FormContainer>
                    </RegistrationForm>
                  ) : (
                    <>
                      <Paragraph>
                        Hello Parents! <br /> <br />
                        <span style={{ color: "#EE741D" }}>
                          {" "}
                          ‘Vocabulary’
                        </span>{" "}
                        is the…most important
                        <span style={{ color: "#EE741D" }}>
                          {" "}
                          ‘First-building-block’{" "}
                        </span>
                        responsible for Listening; Speaking; Reading & Writing…
                        …& every other development in learning!! <br /> <br />{" "}
                        Presenting <br />
                        <span style={{ color: "#EE741D" }}>
                          {" "}
                          VocaBuilder - Solo{" "}
                        </span>
                        <br />
                        - an innovation in Learning! <br /> <br />
                        Your Child’s Best Friend! <br />
                        Champ’s Fun Steps to Word Mastery <br /> <br />
                        You are{" "}
                        <span style={{ color: "#EE741D" }}>
                          {" "}
                          Registered !{" "}
                        </span>{" "}
                        Your link is ready to download . . .
                      </Paragraph>
                    </>
                  )}
                  <SubmitButtonContainer>
                    {/* <SubmitButton onClick={() => RegisterUser()} /> */}
                  </SubmitButtonContainer>
                </SliderTopContainer>
              ) : (
                <SliderTopContainer>
                  <FlexContainer>
                    <RecommendedContainer>
                      Recommended requirements
                      <br />
                      Memory: 8 GB
                      <br /> Graphics Card: Intel HD 2000 <br />
                      CPU: Intel Core i5 2.00GHz <br />
                      File Size: 1.0GB
                      <br /> OS: Windows 10/11 <br />
                    </RecommendedContainer>
                    <MinimumContainer>
                      Minimum requirements
                      <br /> Memory: 4 GB <br />
                      Graphics Card: Intel HD 2000
                      <br /> CPU: Intel Core i3 2.00GHz
                      <br /> File Size: 700MB
                      <br /> OS: Windows 7/8/8.1
                      <br />
                      <br />
                    </MinimumContainer>
                  </FlexContainer>

                  <LoginHeading>Log In to 3H Learning</LoginHeading>
                  {otp_received === false ? (
                    <>
                      <FormContainer>
                        <PrePhoneText>+91</PrePhoneText>
                        <Input
                          type="text"
                          placeholder="Phone Number To Get OTP"
                          value={phone_number}
                          onChange={(e) => onChangePhoneNumber(e)}
                        />
                      </FormContainer>

                      <SubmitButtonContainer>
                        <SubmitButton onClick={() => GetOTP()} />
                      </SubmitButtonContainer>
                    </>
                  ) : (
                    <>
                      <FormContainer>
                        <Input
                          type="text"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={(e) => onChangeOTP(e)}
                        />
                      </FormContainer>

                      <SubmitButtonContainer>
                        <EnterOTPButton onClick={() => EnterOtp()} />
                      </SubmitButtonContainer>
                    </>
                  )}
                </SliderTopContainer>
              )}
            </>
          ) : (
            <>
              <Paragraph>
                Hello Parents! <br /> <br />
                <span style={{ color: "#EE741D" }}> ‘Vocabulary’</span> is
                the…most important
                <span style={{ color: "#EE741D" }}>
                  {" "}
                  ‘First-building-block’{" "}
                </span>
                responsible for Listening; Speaking; Reading & Writing… …& every
                other development in learning!! <br /> <br /> Presenting <br />
                <span style={{ color: "#EE741D" }}> VocaBuilder - Solo </span>
                <br />
                - an innovation in Learning! <br /> <br />
                Your Child’s Best Friend! <br />
                Champ’s Fun Steps to Word Mastery <br /> <br />
              </Paragraph>
              {/* // active */}
            </>
          )}

          {signed_in === true && registered === true ? (
            <FlexLogoContainer>
              <LogoContainer href="https://play.google.com/store/apps/details?id=com.threehl.vocabuildersolo">
                <img src={logoPlay} />
              </LogoContainer>
              <LogoContainer href="https://firebasestorage.googleapis.com/v0/b/threehlweb.appspot.com/o/VocaBuilderSolo.zip?alt=media&token=ef763fcc-b01b-46c4-b8d7-15b5f53ea7e4">
                <img src={logoWindows} />
              </LogoContainer>
            </FlexLogoContainer>
          ) : open_buying_modal === false ? (
            <FlexLogoContainer>
              <LogoLeftContainer>
                <LogoContainer href="https://play.google.com/store/apps/details?id=com.threehl.vocabuildersolo">
                  <img src={logoPlay} />
                </LogoContainer>
                <Price>₹400</Price>
              </LogoLeftContainer>
              <LogoRightContainer>
                <WindowsLogoContainer
                  onClick={() => CheckForAuth()}
                  //  href="https://play.google.com/store/apps/details?id=com.threehl.vocabuildersolo"
                >
                  <img src={logoWindows} />
                </WindowsLogoContainer>
                <Price>₹2999</Price>
                <div>
                  *To use in Laptop / Computer with Windows Operating System
                </div>
              </LogoRightContainer>
            </FlexLogoContainer>
          ) : null}
          <SliderContainer>
            <SliderLeiureTime />
          </SliderContainer>

          <ReactModalContainer>
            <React.Fragment>
              <ModalVideo
                style={{ width: 40 }}
                channel="youtube"
                youtube={{ mute: 1, autoplay: 0 }}
                isOpen={isOpen}
                videoId="L61p2uyiMSo"
                onClose={() => setOpen(false)}
              />
            </React.Fragment>
          </ReactModalContainer>
        </RightContainer>
      </SectionContainer>

      <BannerContainer>
        <img src={homeart3} />
      </BannerContainer>
      <VideoContainer>
        <video controls width="1200">
          <source src="/Videos/VocaBuilderSolo.mp4" type="video/mp4" />
        </video>
      </VideoContainer>

      <FillerContainer></FillerContainer>
    </Container>
  );
};
