import React from "react";
import tw from "twin.macro";

import welcomehomepage from "../Images/welcomehomepage.png";
import ListIcon from "../Images/pencil.png";

const SectionContainer = tw.div`flex items-center 2xl:px-64 mt-20`;
const LeftContainer = tw.div`w-1/2 md:flex hidden md:block justify-end md:pr-40 2xl:pr-20`;
const RightContainer = tw.div`md:w-1/2 flex justify-center md:pl-40 2xl:pl-0`;

const Paragraph = tw.div`text-center  md:text-lg 2xl:text-2xl opacity-75 pl-4`;
const YellowParagraph = tw.div`text-left 2xl:text-2xl text-yellow-800`;
const BlueParagraph = tw.div`text-left 2xl:text-2xl text-blue-400`;
const OrangeParagraph = tw.div`text-left 2xl:text-2xl text-textprimary`;

const Container = tw.div`mt-10`;
const PaddingContainer = tw.div``;

const ListContainer = tw.div`flex items-center my-1`;
const ListIconContainer = tw.div`2xl:w-5 w-5 `;

export default () => {
  return (
    <Container>
      <PaddingContainer>
        <SectionContainer>
          <RightContainer>
            <Paragraph>
              <OrangeParagraph>
                <ListContainer>
                  <ListIconContainer>
                    <img src={ListIcon} />
                  </ListIconContainer>
                  <Paragraph>Charging Times;</Paragraph>
                </ListContainer>
                <ListContainer>
                  <ListIconContainer>
                    <img src={ListIcon} />
                  </ListIconContainer>
                  <Paragraph> Fast Forward Society;</Paragraph>
                </ListContainer>
                <ListContainer>
                  <ListIconContainer>
                    <img src={ListIcon} />
                  </ListIconContainer>
                  <Paragraph>Growing Technology;</Paragraph>
                </ListContainer>
                <ListContainer>
                  <ListIconContainer>
                    <img src={ListIcon} />
                  </ListIconContainer>
                  <Paragraph>Intelligent Children;</Paragraph>
                </ListContainer>
              </OrangeParagraph>
              <br />
              <YellowParagraph>
                <ListContainer>
                  <ListIconContainer>
                    <img src={ListIcon} />
                  </ListIconContainer>
                  <Paragraph>‘Problem-Area’ or ‘Opportunity-Space’?</Paragraph>
                </ListContainer>
                <ListContainer>
                  <ListIconContainer>
                    <img src={ListIcon} />
                  </ListIconContainer>
                  <Paragraph>
                    If you see ‘Opportunity’ in every ‘Problem’,
                  </Paragraph>
                </ListContainer>
                <ListContainer>
                  <ListIconContainer>
                    <img src={ListIcon} />
                  </ListIconContainer>
                  <Paragraph> You are in the right place!</Paragraph>
                </ListContainer>
                <ListContainer>
                  <ListIconContainer>
                    <img src={ListIcon} />
                  </ListIconContainer>
                  <Paragraph>Schools woes are plentiful!</Paragraph>
                </ListContainer>
              </YellowParagraph>
              <br />
              <BlueParagraph>
                <ListContainer>
                  <ListIconContainer>
                    <img src={ListIcon} />
                  </ListIconContainer>
                  <Paragraph>Increasing Competition;</Paragraph>
                </ListContainer>
                <ListContainer>
                  <ListIconContainer>
                    <img src={ListIcon} />
                  </ListIconContainer>
                  <Paragraph> Demanding Parents;</Paragraph>
                </ListContainer>
                <ListContainer>
                  <ListIconContainer>
                    <img src={ListIcon} />
                  </ListIconContainer>
                  <Paragraph>Failing Methodologies;</Paragraph>
                </ListContainer>
                <ListContainer>
                  <ListIconContainer>
                    <img src={ListIcon} />
                  </ListIconContainer>
                  <Paragraph>Fleecing ‘Brands’;</Paragraph>
                </ListContainer>
                <ListContainer>
                  <ListIconContainer>
                    <img src={ListIcon} />
                  </ListIconContainer>
                  <Paragraph>Galloping Study Material Prices;</Paragraph>
                </ListContainer>
              </BlueParagraph>
            </Paragraph>
          </RightContainer>

          <LeftContainer>
            <img src={welcomehomepage} width="70%" />
          </LeftContainer>
        </SectionContainer>
      </PaddingContainer>
    </Container>
  );
};
