import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import tw from "twin.macro";
import "../../node_modules/react-modal-video/scss/modal-video.scss";

const Container = tw.div`fixed flex justify-center top-40 left-Grades w-full z-50`;

export default () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Container>
      <React.Fragment>
        <ModalVideo
          style={{ width: 40 }}
          channel="youtube"
          youtube={{ mute: 1, autoplay: 0 }}
          isOpen={isOpen}
          videoId="L61p2uyiMSo"
          onClose={() => setOpen(false)}
        />
      </React.Fragment>
    </Container>
  );
};
