import React from "react";
import tw from "twin.macro";

import art1 from "../../Images/homeart1.png";
import art2 from "../../Images/homeart2.png";
import dino2 from "../../Images/dino2.png";
import homecloud from "../../Images/homecloud.png";

import SliderLeiureTime from "../LKG/SliderLeisureTimeBG";

const SectionContainer = tw.div`bg-homeart 2xl:px-64 px-20  flex items-center`;
const ImageContainer = tw.div`flex justify-center -mt-1 `;
const DinoImageContainer = tw.div`flex justify-end transform -translate-x-40 2xl:translate-y-20 translate-y-16 2xl:mr-80 mr-28`;
const CloudImageContainer = tw.div`flex justify-end 2xl:mr-80 mr-20 2xl:-mt-40 -mt-32`;

const Heading = tw.div`text-2xl my-1 font-bold text-white`;
const ListParagraph = tw.li` pl-4 my-4 `;

const Container = tw.div``;

const LeftContainer = tw.div`w-3/4 `;
const RightContainer = tw.div`w-1/4 ml-32`;

export default () => {
  return (
    <Container>
      <DinoImageContainer>
        <img src={dino2} />
      </DinoImageContainer>

      <ImageContainer>
        <img src={art1} />
      </ImageContainer>

      <SectionContainer>
        <LeftContainer>
          <div>
            <Heading>Learning Progress Set for Summative Assessment</Heading>
            <ListParagraph>
              Conducting regular{" "}
              <span style={{ color: "#1E3A8A", fontWeight: "bold" }}>
                {" "}
                ‘Summative-Assessments’{" "}
              </span>
              in a year is always a cumbersome job.
            </ListParagraph>
            <ListParagraph>
              The Lead Teacher, in a School, takes a lot of time to ready the
              question papers every year. After creating the master dummy with a
              lot of effort, duplication costs a lot. Moreover, the output of
              black & white question papers is also not so good looking.
            </ListParagraph>
            <ListParagraph>
              Learning Progress Sets{" "}
              <span style={{ color: "#1E3A8A", fontWeight: "bold" }}>
                (6 times in a year){" "}
              </span>{" "}
              are colorful, cost-effective & time-saving.
            </ListParagraph>
            <ListParagraph>
              A set of LPS (28 pages) contains both written as well as oral
              assessments sheets.
            </ListParagraph>
            <ListParagraph>
              An entire set to conduct one exam (for a child) costs less than a
              coffee in a good restaurant.
            </ListParagraph>
            <ListParagraph>
              Children will love it and Parents feel proud with this
              professional output.
            </ListParagraph>
            <ListParagraph>
              <span style={{ color: "#1E3A8A", fontWeight: "bold" }}>
                A Progress Card{" "}
              </span>{" "}
              also given at the beginning of the year.
            </ListParagraph>
            <ListParagraph>
              Even in a small item like assessment sheets, Schools can build a
              quality image around their brand.
            </ListParagraph>
          </div>
        </LeftContainer>
        <RightContainer>
          <SliderLeiureTime />
        </RightContainer>
      </SectionContainer>

      <ImageContainer>
        <img src={art2} />
      </ImageContainer>

      <CloudImageContainer>
        <img src={homecloud} width="20%" />
      </CloudImageContainer>
    </Container>
  );
};
