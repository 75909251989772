import React from "react";
import tw from "twin.macro";

import SliderLeiureTime from "../LKG/SliderLeisureTimeOne";

const Container = tw.div` 2xl:mx-64 mx-20 my-4`;
const LeftContainer = tw.div`w-3/4 pr-16`;
const RightContainer = tw.div`w-1/4 `;
const FlexContainer = tw.div`flex items-center`;

const SubHeading = tw.div`text-xl italic my-4 text-textprimary font-semibold`;
const Heading = tw.div`text-4xl text-center mb-8 font-black text-textprimary`;
const Paragraph = tw.div`text-lg my-4 `;
const TableContainer = tw.div`w-1/2`;

const ListParagraph = tw.li` text-lg my-4 `;

export default () => {
  return (
    <Container>
      <Heading>4 Amaze Textbooks Set (368 Pages)</Heading>
      <FlexContainer>
        <LeftContainer>
          <ListParagraph>
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Full Year pack{" "}
            </span>{" "}
            for Lower Kindergarten / LKG / Pre Primary 1
          </ListParagraph>
          <ListParagraph>
            Relevant for Children after Nursery / Pre-KG
          </ListParagraph>
          <ListParagraph>
            Set of 4 Books{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              (368 Pages - 3 Trimester Books plus 1 Activities Review book)
            </span>
          </ListParagraph>
          <ListParagraph>
            English, Mathematics, Rhymes, Stories, Coloring, General Knowledge /
            EVS, Dialogue Booster / Spoken English, Good Values, Art Works &
            Activities
          </ListParagraph>
          <ListParagraph>
            Full coverage, thick 80 GSM paper, Lucid presentation,
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Step-by-step approach
            </span>{" "}
            makes learning easy & enjoyable
          </ListParagraph>
          <ListParagraph>
            Digital Content{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              (Lessons and Practical)
            </span>{" "}
            available optionally
          </ListParagraph>
          <ListParagraph>
            Play-way Learning of Non-Scholastic Skills (Physical Skills, Memory
            Skills, Cognitive Skills, Sensory Skills, Social and Emotional
            Skills) available as an option. Teachers are provided with
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              VAT (Video, Audio & Text) Support
            </span>
          </ListParagraph>

          <Paragraph>
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              ‘Amaze – Premium Edu-pack for KGs’{" "}
            </span>{" "}
            is a Registered Brand. Over a thousand Schools across India are
            happy customers of this product
          </Paragraph>
        </LeftContainer>

        <RightContainer>
          <SliderLeiureTime />
        </RightContainer>
      </FlexContainer>

      <FlexContainer>
        <TableContainer>
          <SubHeading>Lessons Multimedia</SubHeading>

          <ListParagraph>Thematic Digital Lessons</ListParagraph>
          <ListParagraph>
            Day-to-day Contexts lead to Concepts (Principle of Known to Unknown)
          </ListParagraph>
          <ListParagraph>
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Cartoon Characters
            </span>{" "}
            enable Children to get associated easily
          </ListParagraph>
          <ListParagraph>
            Integrated with every lesson in the Textbook
          </ListParagraph>
          <ListParagraph>
            Learning become easy when made
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Multi – Sensory{" "}
            </span>{" "}
            Best suited for very young Children
          </ListParagraph>
          <ListParagraph>
            Children are able to{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              SEE, LISTEN & REPEAT{" "}
            </span>{" "}
            - Subjects are learnt better
          </ListParagraph>
        </TableContainer>
        <TableContainer>
          <SubHeading>Applications Multimedia </SubHeading>

          <ListParagraph>
            Based on the Principle -{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              ‘No Child should be left out’
            </span>
          </ListParagraph>
          <ListParagraph>
            Digital Practical helps the Teacher find out if all the concepts are
            understood by all the Children.
          </ListParagraph>
          <ListParagraph>
            Ensures that the building blocks are on firm footing during the
            formative years
          </ListParagraph>
          <Paragraph>
            40 minutes of responsible usage of technology in a day is a
            Teacher’s delight! With a blend of other
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              finely designed activities{" "}
            </span>
            for the rest of the day, this Amaze Edu-Pack Curriculum is the best
            in its category.
            <br /> <br />
            Multimedia Lessons are given{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              FREE
            </span>{" "}
            to the Schools taking a certain number of books!
          </Paragraph>
        </TableContainer>
      </FlexContainer>
    </Container>
  );
};
