import React from "react";
import tw from "twin.macro";
import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import SliderAmazeWings from "../Components/OurApplications/SliderAmazeWings";

import SectionOne from "../Components/PreKG/SectionOne";
import SectionBG from "../Components/PreKG/SectionBG";
import SectionThree from "../Components/PreKG/SectionThree";
import SectionFour from "../Components/PreKG/SectionFour";
import SectionSix from "../Components/PreKG/SectionSix";

import img5 from "../Images/PreKG/KGPrep1.png";
import img6 from "../Images/PreKG/KGPrep2.png";
import img7 from "../Images/PreKG/KGPrep3.png";

import bg3 from "../Images/homeart3.png";
import bg4 from "../Images/homeart4.png";
import art1 from "../Images/homeart1.png";

const Container = tw.div` font-comicsans`;
const FlexContainer = tw.div`flex justify-center px-40 py-10 bg-welcomecolor`;
const Heading = tw.div`text-5xl text-center 2xl:mx-64 mx-20 my-24 font-black text-blue-900`;
const ImageContainer = tw.div`flex justify-center`;

const BGImageContainer = tw.div`flex justify-center -mt-1 `;
const ColorHeading = tw.div`text-5xl text-center font-bold text-blue-900 bg-welcomecolor`;
const ColorParagraph = tw.div`text-lg px-64 bg-welcomecolor`;

const NewContainerBG = tw.div`font-comicsans mb-28 p-8 2xl:px-64 md:px-32  bg-homeart`;
const SectionBGContainer = tw.div`md:flex items-center justify-around`;
const LeftContainer = tw.div`md:w-2/4  `;
const RightContainer = tw.div`md:w-2/4 md:mx-8`;

const ReactModalContainer = tw.div`fixed md:flex justify-center top-40 left-Grades md:w-full z-50`;

const AmazeFlexContainer = tw.div`md:flex justify-center`;
const HeadingBG = tw.div`text-4xl mb-12 text-center font-black text-blue-900`;
const Paragraph = tw.div`text-lg opacity-80 text-left`;
const SplitParagraph = tw.div`text-lg md:mx-4 opacity-80 text-left`;

export default () => {
  return (
    <Container>
      <Heading>Pre KG Edu-Pack</Heading>

      <BGImageContainer>
        <img src={art1} />
      </BGImageContainer>
      <NewContainerBG>
        <HeadingBG>AMAZE WINGS</HeadingBG>
        <SectionBGContainer>
          <RightContainer>
            <Paragraph>
              Amaze Wings comes with many extra benefits! <br /> <br />
            </Paragraph>
            <AmazeFlexContainer>
              <SplitParagraph>
                <span style={{ color: "#1E3A8A" }}>
                  Individual and Group Activities
                </span>{" "}
                <br /> <br />
                • Physical Skills <br />
                • Memory Skills <br />
                • Cognitive Skills <br />
                • Social and Emotional Skills <br />
                • Sensory Skills <br /> <br />
              </SplitParagraph>
              <SplitParagraph>
                <span style={{ color: "#1E3A8A" }}>
                  Comprehensive Premium Coverage:{" "}
                </span>
                <br /> <br />
                • Classic Edu-Pack Combo - Look no beyond! <br />
                • 10 Must Pre-Kindergartn Skills <br />
                • 3 Timester Books <br />
                • 3 KG Prep Android Apps: Self-Learn model <br />
                • IntelliGames: Develop Non-Scholastic Skills <br />
                • Habit Formation & Sustenance <br />
              </SplitParagraph>
            </AmazeFlexContainer>
          </RightContainer>

          <LeftContainer>
            <SliderAmazeWings />
          </LeftContainer>
        </SectionBGContainer>
      </NewContainerBG>

      <SectionOne />

      {/* ----------------------------------------------------------------*/}

      <SectionBG />

      {/* ----------------------------------------------------------------*/}

      <SectionThree />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <SectionFour />

      {/* ----------------------------------------------------------------*/}

      <ImageContainer>
        <img src={bg3} />
      </ImageContainer>
      <ColorHeading>
        KG Prep – 1, 2 & 3 Apps <br /> <br />
      </ColorHeading>
      <ColorParagraph>
        3 KG Prep Apps are given FREE to the Children. Children can run these
        APPS in the mobile phones of their Parents. They can be practiced at
        home one each at the end of each trimester in a year. Mothers can also
        spend quality time with their wards. This App will prepare the children
        for getting into LKG. <br /> <br />
      </ColorParagraph>
      <FlexContainer>
        <ImageContainer>
          <img src={img5} width="40%" />
        </ImageContainer>
        <ImageContainer>
          <img src={img6} width="40%" />
        </ImageContainer>
        <ImageContainer>
          <img src={img7} width="40%" />
        </ImageContainer>
      </FlexContainer>
      <ImageContainer>
        <img src={bg4} />
      </ImageContainer>

      {/* ----------------------------------------------------------------*/}

      <Heading>Pre-KG / Nursery ACE Worksheets</Heading>

      <SectionSix />
    </Container>
  );
};
