import React from "react";
import tw from "twin.macro";

import SectionOne from "../Components/LKG/SectionOne";
import SectionThree from "../Components/LKG/SectionThree";
import SectionFour from "../Components/LKG/SectionFour";
import SectionE from "../Components/LKG/SectionE";

import homeart3 from "../Images/homeart3.png";
import SectionBG from "../Components/LKG/SectionBG";

const Container = tw.div` font-comicsans`;
const BannerContainer = tw.div``;
const FillerContainer = tw.div`h-40 -mb-48 bg-welcomecolor`;

const FlexContainer = tw.div`flex justify-center bg-welcomecolor`;
const Heading = tw.div`text-5xl  text-center 2xl:mx-64 mx-20 my-24 font-black text-blue-900`;

export default () => {
  return (
    <Container>
      <Heading>LKG Edu-Pack</Heading>
      <SectionOne />
      <br />
      <br /> <br />
      <SectionThree />
      <br /> <br /> <br />
      <SectionFour />
      <br /> <br /> <br />
      {/* <SectionSix /> */}
      <SectionBG />
      <br /> <br /> <br />
      <SectionE />
      <BannerContainer>
        <img src={homeart3} />
      </BannerContainer>
      <FlexContainer>
        <video controls width="1200">
          <source src="/Videos/school.mp4" type="video/mp4" />
        </video>
      </FlexContainer>
      <FillerContainer></FillerContainer>
      {/* ----------------------------------------------------------------*/}
    </Container>
  );
};
