import React from "react";
import tw from "twin.macro";

import art1 from "../../Images/homeart1.png";
import art2 from "../../Images/homeart2.png";
import dino2 from "../../Images/dino2.png";
import homecloud from "../../Images/homecloud.png";

const SectionContainer = tw.div`bg-homeart 2xl:px-64`;
const ImageContainer = tw.div`flex justify-center -mt-1 `;
const DinoImageContainer = tw.div`flex justify-end transform -translate-x-40 2xl:translate-y-20 translate-y-16 2xl:mr-80 mr-28`;
const CloudImageContainer = tw.div`flex justify-end 2xl:mr-80 mr-20 2xl:-mt-40 -mt-32`;

const ColorHeading = tw.div`text-5xl text-center font-bold text-blue-900 `;
const SecondColorHeading = tw.div` text-lg italic py-4 text-blue-500 font-semibold  px-64 `;
const ColorParagraph = tw.div`text-lg px-64 `;

const Container = tw.div``;

export default () => {
  return (
    <Container>
      <DinoImageContainer>
        <img src={dino2} />
      </DinoImageContainer>

      <ImageContainer>
        <img src={art1} />
      </ImageContainer>

      <SectionContainer>
        <ColorHeading>Multimedia Lessons</ColorHeading>

        <SecondColorHeading>Primary Objective:</SecondColorHeading>
        <ColorParagraph>
          Learning enabled only through{" "}
          <span style={{ color: "#1E3A8A", fontWeight: "bold" }}>
            {" "}
            ‘read-only’{" "}
          </span>{" "}
          books is a thing of the past.
          <br /> <br />
          We have made it Multi-Sensory! <br /> <br />
          Thematic Multimedia Lessons (integrated to each of the textbook
          lessons) with Cartoon Characters make the learning enjoyable and
          holistic. Not mere{" "}
          <span style={{ color: "#1E3A8A", fontWeight: "bold" }}>
            {" "}
            ‘Reading’!{" "}
          </span>{" "}
          But, every Child in the class is engaged. They{" "}
          <span style={{ color: "#1E3A8A", fontWeight: "bold" }}>
            {" "}
            SEE, LISTEN & REPEAT
          </span>
          . The Digital Content attracts even the slowest of the child in the
          classroom. <br /> <br />
          30 minutes of responsible usage of technology in a day is a Teacher’s
          delight! With a blend of other finely designed activities for the rest
          of the day, this KG Coach Edu-Pack Curriculum is the best in its
          category. <br /> <br />
          Multimedia Lessons are given{" "}
          <span style={{ color: "#1E3A8A", fontWeight: "bold" }}>
            {" "}
            FREE{" "}
          </span>{" "}
          to the Schools taking a certain number of books! <br />
        </ColorParagraph>
      </SectionContainer>

      <ImageContainer>
        <img src={art2} />
      </ImageContainer>

      <CloudImageContainer>
        <img src={homecloud} width="20%" />
      </CloudImageContainer>
    </Container>
  );
};
