import React from "react";
import tw from "twin.macro";

const Heading = tw.div`text-5xl text-center mx-64 my-24 font-black text-blue-900`;
const Paragraph = tw.div`opacity-75 text-lg`;
const ListParagraph = tw.li` pl-4 my-4`;

const Container = tw.div` font-comicsans mx-64`;

export default () => {
  return (
    <Container>
      <Heading>Refund</Heading>
      <Paragraph>
        Only if the application does not work even after the intervention and
        guidance of our technical team, the refund will be allowed. <br />{" "}
        <br />
        A Customer needs to raise the alert within 5 working days from the date
        of the purchase to be eligible for a refund. The Customer raising an
        alert after 5 working days from the date of purchase will NOT be
        eligible for any refund. <br /> <br />
        Only those products will be eligible for the refund policy that is
        approved by our technical team. <br /> <br />
        If you have received email / message from us confirming your refund
        request, then we have initiated your refund request and following up
        with the financial institution for the same. <br /> <br />
        Sometimes the financial institution takes longer than the time
        procedure. If the refund has not happened by the date of promise you can
        contact us and we will be glad to help you. <br /> <br />
        Your payment is secured. In case of prepaid order (Credit/Debit cards,
        Netbanking, UPI) this amount will be refunded in the same account from
        which the payment is made within 7-14 working days (service charges will
        be applicable). <br />
        What are the details you require to process NEFT refund? <br /> <br />
        Once you agree for NEFT refund, you will receive an email from Moglix
        customer support team asking for...
        <ListParagraph>Your Bank Name</ListParagraph>
        <ListParagraph>Your Bank Account Number</ListParagraph>
        <ListParagraph>Account Holder’s name</ListParagraph>
        <ListParagraph>IFSC code</ListParagraph>
        <ListParagraph>
          Location of the Bank. We initiate refund process as soon as we receive
          the details from you and it takes 7-10 working days for the money to
          be credited to your account.
        </ListParagraph>
      </Paragraph>

      <Heading>GOVERNING LAW</Heading>

      <Paragraph>
        This Policy shall be governed by and interpreted and construed in
        accordance with the laws of India. The place of jurisdiction shall
        exclusively be in Chennai, Tamil Nadu. In the event of any dispute
        arising out of this Policy the same shall be settled by a binding
        arbitration conducted by a sole arbitrator, appointed jointly by both
        parties and governed by the Arbitration and Conciliation Act, 1996. The
        seat and venue of arbitration shall be Chennai, Tamil Nadu.
        <ListParagraph>
          Payment Data: We collect data necessary to process your payment if you
          make purchases, such as your payment instrument number (such as a
          credit card number), and the security code associated with your
          payment instrument. All payment data is stored by our payment
          processor and you should review its privacy policies and contact the
          payment processor directly to respond to your questions.
        </ListParagraph>
      </Paragraph>
    </Container>
  );
};
