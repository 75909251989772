import React, { useState } from "react";
import tw from "twin.macro";
import SliderLeiureTime from "../PreKG/SliderLeisureTimeOne";

const Container = tw.div` 2xl:mx-64 mx-20 my-4`;
const FlexContainer = tw.div`flex items-center`;
const LeftContainer = tw.div`w-3/4 pr-16`;
const RightContainer = tw.div`w-1/4 `;

const SubHeading = tw.div`text-xl italic my-4 text-textprimary font-semibold`;
const Heading = tw.div`text-4xl text-center mb-8 font-black text-textprimary`;
const Paragraph = tw.div`text-lg my-4 `;
const HighlightText = tw.div``;

const ListParagraph = tw.li` text-lg my-4 `;

export default () => {
  const [open, set_open] = useState(false);

  return (
    <Container>
      <Heading>KG Coach Text Books</Heading>

      <FlexContainer>
        <LeftContainer>
          <SubHeading>Primary Objective: </SubHeading>
          <Paragraph>
            The Child has to get used to the School as an{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              ‘environment’{" "}
            </span>{" "}
            and also to learning as a{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              ‘process’!{" "}
            </span>
            When a Child @ 2.5 to 3 Yrs of age is moving from a well protected
            environment to an open environment, this Syllabus forms a bridge
            between Play-group and Lower Kindergarten.
          </Paragraph>
          <ListParagraph>
            A formal introduction to the world around is required. A liking for
            the School and its learning environment has to be created. More
            importantly,{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              the Child is required to share a healthy space
            </span>{" "}
            with other children as well as accept the role of a Teacher in the
            process.
          </ListParagraph>
          <ListParagraph>
            The design of the Syllabus and the Methodology should be based on
            the above issues. The blend of various activities and its flow (easy
            to tough) should comply with the above. A good program should ensure{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              a perfect take-off for the Child
            </span>{" "}
            into the wonderful world of learning.
          </ListParagraph>
          <ListParagraph>
            The{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              ‘fun’{" "}
            </span>
            element should be included in every step taken.
          </ListParagraph>

          <Paragraph>
            All the above points are incorporated in a right blend in the
            Textbooks, Worksheets and all the supplementary items of the
            Edu-Pack for Pre-KG / Nursery!
          </Paragraph>
        </LeftContainer>
        <RightContainer>
          <SliderLeiureTime />

          {/* --------------------------------------------------- */}

          {/* <img
            src={image1}
            onClick={() => {
              set_open(true);
            }}
          />
      {open === true ? <ImagePopup /> : null} */}

          {/* --------------------------------------------------- */}
        </RightContainer>
      </FlexContainer>
    </Container>
  );
};
