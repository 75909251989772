import React from "react";
import tw from "twin.macro";

import art1 from "../Images/homeart1.png";
import art2 from "../Images/homeart2.png";
import dino2 from "../Images/dino2.png";
import homecloud from "../Images/homecloud.png";
import YearsExp from "../Images/30Years.png";

const SectionContainer = tw.div`bg-homeart flex justify-center md:px-64`;
const TabContainer = tw.div`w-1/2 md:w-1/4 text-center py-12 ml-28 md:mx-10 2xl:mx-0 `;
const FlexContainer = tw.div`flex justify-center my-10`;
const ImageContainer = tw.div`flex justify-center -mt-1 `;
const LogoImageContainer = tw.div`flex justify-center -mr-32 md:h-56 h-32 my-auto w-1/2`;
const DinoImageContainer = tw.div`flex justify-end transform -translate-x-40 2xl:translate-y-20 translate-y-16 2xl:mr-80 mr-28`;
const CloudImageContainer = tw.div`flex hidden md:block justify-end 2xl:mr-80 mr-20 2xl:-mt-40 -mt-32`;

const Heading = tw.div`text-5xl my-1 font-bold text-white`;
const SubHeading = tw.div`text-lg my-1 font-bold text-white`;
const Paragraph = tw.div``;
const ListParagraph = tw.li` pl-4 my-4 `;

const Container = tw.div``;
const Seperator = tw.div` h-1 my-20 bg-sub`;

export default () => {
  return (
    <Container>
      <DinoImageContainer>
        <img src={dino2} />
      </DinoImageContainer>

      <ImageContainer>
        <img src={art1} />
      </ImageContainer>

      <SectionContainer>
        <LogoImageContainer>
          <img src={YearsExp}/>
        </LogoImageContainer>
        {/* <TabContainer>
          <Heading>30</Heading>
          <SubHeading>Years of Experience</SubHeading>
        </TabContainer> */}

        <TabContainer>
          <SubHeading>Products Popular in </SubHeading>
          <Heading> 2150+</Heading>
          <SubHeading> Towns in India</SubHeading>
        </TabContainer>
      </SectionContainer>

      <ImageContainer>
        <img src={art2} />
      </ImageContainer>

      <CloudImageContainer>
        <img src={homecloud} width="20%" />
      </CloudImageContainer>
    </Container>
  );
};
