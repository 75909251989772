import React from "react";
import tw from "twin.macro";

import SliderLeiureTime from "../UKG/SliderLeisureTimeThree";

const Container = tw.div` 2xl:mx-64 mx-20 my-4`;
const LeftContainer = tw.div`w-2/4 items-center`;
const RightContainer = tw.div`w-2/4 pl-16`;
const FlexContainer = tw.div`flex items-center`;

const SubHeading = tw.div`text-xl italic my-4 text-textprimary font-semibold`;
const Heading = tw.div`text-4xl text-center mb-8 font-black text-textprimary`;

const ListParagraph = tw.li` text-lg my-4 `;

export default () => {
  return (
    <Container>
      <Heading>UKG Worksheets - a set of 3 books</Heading>
      <FlexContainer>
        <LeftContainer>
          <SliderLeiureTime />
        </LeftContainer>

        <RightContainer>
          <SubHeading>
            English, Mathematics, EVS / Gk & Coloring - 284 Pages
          </SubHeading>
          <ListParagraph>
            Early Learning Interactive Activity Worksheets of International
            Standards with Indian Context for Lower Kindergarten / Pre-Primary 1
          </ListParagraph>
          <ListParagraph>
            Brand – ACE Worksheets – Popular in{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              2150{" "}
            </span>{" "}
            towns in India
          </ListParagraph>
          <ListParagraph>
            English (132 Pages), Mathematics (84 Pages) & General Knowledge &
            Coloring (68 Pages)
          </ListParagraph>
          <ListParagraph>
            Also available as loose sheets with file and pouch!
          </ListParagraph>
          <ListParagraph>
            Find & fix{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              ‘learning-gaps’{" "}
            </span>
            in children
          </ListParagraph>
          <ListParagraph>
            Lakhs of satisfied Teachers and happy Mothers
          </ListParagraph>
          <ListParagraph>
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Reinforces the entire basic skills,
            </span>{" "}
            concepts & topics meant for LKG
          </ListParagraph>
          <ListParagraph>
            Kids learn & understand extra concepts not covered in School
          </ListParagraph>
          <ListParagraph>
            Compelling topics, Appealing activities, Lovely layout;
          </ListParagraph>
          <ListParagraph>Thick paper; Colorful illustrations</ListParagraph>
          <ListParagraph>
            Enhances the{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Child's imagination and enthusiasm{" "}
            </span>
          </ListParagraph>
        </RightContainer>
      </FlexContainer>
    </Container>
  );
};
