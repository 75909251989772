// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";
import {getAuth} from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyBG_eNXGTcTwEuEtvvzqaJSQjPLASZZD1A",

  authDomain: "threehlweb.firebaseapp.com",

  projectId: "threehlweb",

  storageBucket: "threehlweb.appspot.com",

  messagingSenderId: "552736656927",

  appId: "1:552736656927:web:1ba87f82475a2641a30f2a",

  measurementId: "G-2N17N1H0BY"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
export const auth=getAuth(app);
export const db=getFirestore(app);