import React from "react";
import tw from "twin.macro";

import SliderLeiureTime from "../LKG/SliderLeisureTimeFour";
import SliderLeiureTimeNew from "../LKG/SliderLeisureTimeFourNew";

const Container = tw.div` 2xl:mx-64 mx-20 my-4`;
const LeftContainer = tw.div`w-3/4 pr-16`;
const RightContainer = tw.div`w-1/4`;

const SecondLeftContainer = tw.div`w-1/4 `;
const SecondRightContainer = tw.div`w-3/4 pl-16 flex justify-end`;
const FlexContainer = tw.div`flex items-center`;

const Heading = tw.div`text-4xl text-center mb-8 font-black text-textprimary`;
const ListParagraph = tw.li` text-lg my-4 `;

export default () => {
  return (
    <Container>
      <Heading>Writing Practice - English / Mathematics / GK</Heading>
      <FlexContainer>
        <LeftContainer>
          <ListParagraph>
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Develops & Builds Writing Skills{" "}
            </span>{" "}
            - the 4th Pillar of Learning
          </ListParagraph>
          <ListParagraph>
            Pre-Written Copy Writing books in multi-color
          </ListParagraph>
          <ListParagraph>
            Step-by-step guide:
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Enables productive engagement
            </span>{" "}
            for good handwriting
          </ListParagraph>
          <ListParagraph>
            236 pages of Writing Practice for Children of LKG (age 4-6) in
            English, GK and Mathematics that goes hand in hand with the
            curriculum covered in the textbooks
          </ListParagraph>
          <ListParagraph>
            No need for the Teachers to write in each of the pages
          </ListParagraph>
          <ListParagraph>
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Saves over 400 hours in a year
            </span>{" "}
            of the Teacher from repetitive / monotonous tasks
          </ListParagraph>
          <ListParagraph>
            Customizable – Space for additional practice for more difficult
            items
          </ListParagraph>
          <ListParagraph>
            Integrates Visual, Motor and Cognitive Processes
          </ListParagraph>
          <ListParagraph>
            Good quality
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              80GSM thick paper
            </span>{" "}
            helps erase and practice more times
          </ListParagraph>
          <ListParagraph>
            Helps the Child to get good writing practice and identify the
            concepts correctly
          </ListParagraph>
        </LeftContainer>

        <RightContainer>
          <SliderLeiureTime />
        </RightContainer>
      </FlexContainer>

      <FlexContainer>
        <SecondLeftContainer>
          <SliderLeiureTimeNew />
        </SecondLeftContainer>

        <SecondRightContainer>
          <div>
            <ListParagraph>
              Benefits Learning & Reinforcement –{" "}
              <span
                style={{
                  color: "#1E3A8A",
                  fontWeight: "bold",
                  fontSize: "105%",
                }}
              >
                {" "}
                WRITE; REVIEW; REVISE & PRACTICE
              </span>
            </ListParagraph>
            <ListParagraph>
              Child develops positive study skills & habits to start working
              independently
            </ListParagraph>
            <ListParagraph>
              Activates visual perception of letters – Builds reading fluency &
              confidence
            </ListParagraph>
            <ListParagraph>
              The first words Children{" "}
              <span
                style={{
                  color: "#1E3A8A",
                  fontWeight: "bold",
                  fontSize: "105%",
                }}
              >
                READ{" "}
              </span>{" "}
              are often the ones they{" "}
              <span
                style={{
                  color: "#1E3A8A",
                  fontWeight: "bold",
                  fontSize: "105%",
                }}
              >
                WRITE{" "}
              </span>
            </ListParagraph>
            <ListParagraph>
              Head Start – Children gain in handwriting, spelling & punctuation
            </ListParagraph>
            <ListParagraph>
              Parents learn what their Child is learning at School through
              reviewing
            </ListParagraph>
            <ListParagraph>
              Use{" "}
              <span
                style={{
                  color: "#1E3A8A",
                  fontWeight: "bold",
                  fontSize: "105%",
                }}
              >
                1 sheet-a-day
              </span>{" "}
              or as per the micro planner given to the Teachers
            </ListParagraph>
            <ListParagraph>
              <span
                style={{
                  color: "#1E3A8A",
                  fontWeight: "bold",
                  fontSize: "105%",
                }}
              >
                {" "}
                100% colorful; Most cost-effective
              </span>{" "}
              than anything available in the market
            </ListParagraph>
            <ListParagraph>
              35K satisfied Teachers and 4L happy Mothers across India
            </ListParagraph>
            <ListParagraph>
              Used by lakhs of students across
              <span
                style={{
                  color: "#1E3A8A",
                  fontWeight: "bold",
                  fontSize: "105%",
                }}
              >
                {" "}
                2150 towns
              </span>{" "}
              in India
            </ListParagraph>
          </div>
        </SecondRightContainer>
      </FlexContainer>
    </Container>
  );
};
