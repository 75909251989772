import React, { useState } from "react";
import tw from "twin.macro";
import SliderLeisureTimeOne from "../Components/DialogueBooster/SliderLeisureTimeOne";
import { AiOutlineCloseCircle } from "react-icons/ai";

import image01 from "../Images/DialogueBooster/DBLeaflet1.jpg";
import image02 from "../Images/DialogueBooster/DBLeaflet2.jpg";

import homeart3 from "../Images/homeart3.png";

const images = [image01, image02];

const Container = tw.div` font-comicsans `;
const FlexContainer = tw.div`flex md:px-40 px-4 2xl:px-0 justify-center bg-welcomecolor`;
const FillerContainer = tw.div`h-40 md:-mb-80 -mb-32 bg-welcomecolor`;

const BannerContainer = tw.div``;
const ImageContainer = tw.div`flex mx-64  mt-20 justify-center`;
const NewImageContainer = tw.div`flex mx-64 mt-20 justify-center`;
const Heading = tw.div`text-5xl text-center 2xl:mx-64 mx-20 my-24 font-black text-blue-900`;

const SliderContainer = tw.div` text-textprimary text-center`;

const FixedContainer = tw.div`fixed mx-auto md:w-2/4 inset-x-0 top-10 inset-0 flex justify-center items-center`;

const IconContainer = tw.div` flex justify-end `;
const ModalContainer = tw.div`m-20  z-50 flex justify-center`;

export default () => {
  const [open_modal, set_open_modal] = useState(false);
  const [image_to_show, set_image_to_show] = useState(null);
  const OpenModal = (image) => {
    set_open_modal(!open_modal);
    set_image_to_show(image);
  };

  return (
    <Container>
      {/* <SliderLeisureTimeOne /> */}
      <Heading>Dialogue Booster</Heading>
      <ImageContainer>
        {images.map((img, idx) => (
          <img
            src={img}
            onClick={(e) => OpenModal(img)}
            alt={img}
            width="30%"
          />
        ))}
      </ImageContainer>
      {open_modal === true ? (
        <FixedContainer>
          <ModalContainer>
            <img src={image_to_show} width="70%" />
            <IconContainer onClick={() => set_open_modal(false)}>
              <AiOutlineCloseCircle size="48" />
            </IconContainer>
          </ModalContainer>
        </FixedContainer>
      ) : null}

      {/* <ImageContainer>
        <img src={image01} alt="img" width="35%" />
        <img src={image02} alt="img" width="35%" />
      </ImageContainer> */}

      <BannerContainer>
        <img src={homeart3} />
      </BannerContainer>
      <FlexContainer>
        <video controls width="1000">
          <source
            src="/Videos/DialogueBoosterEnglishVideoPresentation.mp4"
            type="video/mp4"
          />
        </video>
      </FlexContainer>

      <FillerContainer></FillerContainer>
    </Container>
  );
};
