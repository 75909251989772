import React from "react";
import tw from "twin.macro";

import ahaan from "../Images/jollypoint2R.png";
const Container = tw.div`items-center 2xl:mx-64 md:mx-20 mx-8 my-4`;
const FlexContainer = tw.div`md:flex items-center`;
const LeftContainer = tw.div`md:w-1/4 w-2/4 flex justify-center`;
const RightContainer = tw.div`md:w-3/4 md:ml-16`;

const Heading = tw.div`2xl:text-5xl text-4xl text-center my-4 font-black text-blue-900`;
const SecondHeading = tw.div`2xl:text-5xl text-4xl text-center mb-8 font-black text-textprimary`;
const Paragraph = tw.div`md:text-lg my-4 opacity-80`;

export default () => {
  return (
    <Container>
      <Heading>Welcome</Heading>

      <FlexContainer>
        <LeftContainer>
          {/* <SliderLeiureTime /> */}

          <img src={ahaan} width="75%" />
        </LeftContainer>

        <RightContainer>
          <Paragraph>
            The Professional Promoters behind 3H Learning have{" "}
            <span style={{ color: "#1E3A8A", fontWeight: "bold" }}>
              {" "}
              empowered over 3150 Schools / Centers{" "}
            </span>{" "}
            across India in the last 30+years. Every month, more and more
            Schools / Centers join this list of happy clients!
            <br /> <br />
            Veterans in the field of Learning & Education with over 1000
            collective years in Teaching & Designing form 3H Learning’s
            backbone!
            <br /> <br />
            <span style={{ color: "#1E3A8A", fontWeight: "bold" }}>
              {" "}
              Innovative Edu-packs{" "}
            </span>{" "}
            from 3H Learning helps these Schools / Centers to provide the{" "}
            <span style={{ color: "#1E3A8A", fontWeight: "bold" }}>
              best-in-its-class{" "}
            </span>{" "}
            education to their students; this helps them face their competition
            with confidence.
            <br /> <br />
            Being in touch with education institutions for more than a quarter
            century has also helped 3H Learning understand the needs of the
            end-users better.
            <br /> <br />
            Initiating original research to bring out{" "}
            <span style={{ color: "#1E3A8A", fontWeight: "bold" }}>
              {" "}
              first-of-its-kind{" "}
            </span>
            innovative products is the hall mark of 3H Learning.
          </Paragraph>

          {/* <PrimaryLink>
            <IconContainer>
              <AiFillPlayCircle size="50" />
            </IconContainer>
            <AnimateIconContainer>
              <TbCircleDotted size="85" />
            </AnimateIconContainer>
            <TextContainer>Play Video</TextContainer>
          </PrimaryLink> */}
          {/* 
        <ButtonsContainer>
          <ButtonOne href="/OurBooks">
            <IconContainerOne>
              <RiNumber1 />
            </IconContainerOne>
            <ButtonTextOne>Our Books</ButtonTextOne>
          </ButtonOne>

           <ButtonTwo href="/OurApplication">
            <IconContainerTwo><RiNumber2 /></IconContainerTwo>
            <ButtonTextTwo>Our Applications</ButtonTextTwo>
          </ButtonTwo> 
        </ButtonsContainer> */}
        </RightContainer>
      </FlexContainer>
    </Container>
  );
};
