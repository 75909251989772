import "./../Carousel.css";
import tw from "twin.macro";
import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Slider from "react-slick";
import image1 from "../../Images/BeginnersHindi/WritingPracticeHindiLevel/01.jpg";
import image2 from "../../Images/BeginnersHindi/WritingPracticeHindiLevel/02.jpg";
import image3 from "../../Images/BeginnersHindi/WritingPracticeHindiLevel/03.jpg";
import image4 from "../../Images/BeginnersHindi/WritingPracticeHindiLevel/04.jpg";

const images = [image1, image2, image3, image4];
const Container = tw.div``;
const SliderContainer = tw.div` text-textprimary text-center`;

const FixedContainer = tw.div`fixed mx-auto md:w-2/4 inset-x-0 top-10 inset-0 flex justify-center items-center`;

const IconContainer = tw.div` flex justify-end `;
const ModalContainer = tw.div`m-20  z-50 flex justify-center`;

function App() {
  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 1500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  const [open_modal, set_open_modal] = useState(false);
  const [image_to_show, set_image_to_show] = useState(null);
  const OpenModal = (image) => {
    set_open_modal(!open_modal);
    set_image_to_show(image);
  };
  return (
    <Container>
      <SliderContainer>
        <Slider {...settings}>
          {images.map((img, idx) => (
            <img src={img} onClick={(e) => OpenModal(img)} alt={img} />
          ))}
        </Slider>
      </SliderContainer>
      {open_modal === true ? (
        <FixedContainer>
          <ModalContainer>
            <img src={image_to_show} width="100%" />
            <IconContainer onClick={() => set_open_modal(false)}>
              <AiOutlineCloseCircle size="48" />
            </IconContainer>
          </ModalContainer>
        </FixedContainer>
      ) : null}
    </Container>
  );
}

export default App;
