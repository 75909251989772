import React from "react";
import tw from "twin.macro";

import SliderLeiureTime from "../UKG/SliderLeisureTimeSix";

const Container = tw.div`flex items-center  2xl:mx-64 mx-20 my-4`;
const LeftContainer = tw.div`w-3/4 `;
const RightContainer = tw.div`w-1/4 ml-32`;

const Heading = tw.div`text-4xl mb-8 font-black text-textprimary`;

const ListParagraph = tw.li` text-lg my-4 `;

export default () => {
  return (
    <Container>
      <LeftContainer>
        <div>
          <Heading>Learning Progress Set for Summative Assessment</Heading>
          <ListParagraph>
            Conducting regular{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              ‘Summative-Assessments’{" "}
            </span>{" "}
            in a year is always a cumbersome job.
          </ListParagraph>
          <ListParagraph>
            The Lead Teacher, in a School, takes a lot of time to ready the
            question papers every year. After creating the master dummy with a
            lot of effort, duplication costs a lot. Moreover, the output of
            black & white question papers is also not so good looking.
          </ListParagraph>
          <ListParagraph>
            Learning Progress Sets{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              (6 times in a year){" "}
            </span>{" "}
            are colorful, cost-effective & time-saving.
          </ListParagraph>
          <ListParagraph>
            A set of LPS (28 pages) contains both written as well as oral
            assessments sheets.
          </ListParagraph>
          <ListParagraph>
            An entire set to conduct one exam (for a child){" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              costs less than a coffee{" "}
            </span>{" "}
            in a good restaurant.
          </ListParagraph>
          <ListParagraph>
            Children will love it and Parents feel proud with this professional
            output.
          </ListParagraph>
          <ListParagraph>
            A Progress Card also given at the beginning of the year.
          </ListParagraph>
          <ListParagraph>
            Even in a small item like assessment sheets, Schools can build a
            quality image around their brand.
          </ListParagraph>
        </div>
      </LeftContainer>
      <RightContainer>
        <SliderLeiureTime />
      </RightContainer>
    </Container>
  );
};
