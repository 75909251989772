import React from "react";
import tw from "twin.macro";

const Heading = tw.div`text-3xl  font-semibold text-center my-10 pt-10`;

const Container = tw.div` font-comicsans`;

export default () => {
  return (
    <Container>
      <Heading>Page Under Construction</Heading>
    </Container>
  );
};
