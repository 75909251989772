import React from "react";
import tw from "twin.macro";

import SliderLeiureTime from "../PreKG/SliderLeisureTimeThree";

const Container = tw.div`flex items-center 2xl:mx-64 mx-20 my-4`;
const LeftContainer = tw.div`w-1/4 `;
const RightContainer = tw.div`w-3/4 pl-16`;

const Heading = tw.div`text-4xl mb-8 font-black text-textprimary`;
const Paragraph = tw.div`text-lg my-4 `;

const ListParagraph = tw.li` text-lg my-4 `;

export default () => {
  return (
    <Container>
      <LeftContainer>
        <SliderLeiureTime />
      </LeftContainer>

      <RightContainer>
        <Heading>Cognitive Cards</Heading>
        <Paragraph>They are Cognitive Cards! Not flash cards</Paragraph>

        <ListParagraph>
          19 Cognitive development activities with{" "}
          <span
            style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
          >
            {" "}
            54 pictures;
          </span>{" "}
          A set of cards are given to the Parents for engaging their wards after
          6 months of exposure to the course.
        </ListParagraph>
        <ListParagraph>
          <span
            style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
          >
            {" "}
            Coverage:
          </span>{" "}
          Identifying items, improving sharpness, developing focus, igniting
          logical thinking, sequencing, finding the difference, finding what is
          common etc
        </ListParagraph>
        <ListParagraph>
          Parents can spend real quality time with their wards.{" "}
        </ListParagraph>

        <Paragraph>
          Children can start using them at home{" "}
          <span
            style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
          >
            {" "}
            from 15th January during an academic year.
          </span>
        </Paragraph>
      </RightContainer>
    </Container>
  );
};
