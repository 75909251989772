import React from "react";
import tw from "twin.macro";

import SliderLeiureTime from "../BeginnerHindi/SliderLeisureTimeFour";
import SliderLeiureTimeNew from "../BeginnerHindi/SliderLeisureTimeFourNew";

const Container = tw.div` 2xl:mx-64 mx-20 my-4`;
const LeftContainer = tw.div`w-3/4 pr-16`;
const RightContainer = tw.div`w-1/4`;

const SecondLeftContainer = tw.div`w-1/4 `;
const SecondRightContainer = tw.div`w-3/4 pl-16 flex justify-end`;
const FlexContainer = tw.div`flex items-center`;

const Heading = tw.div`text-4xl text-center mb-8 font-black text-textprimary`;

const ListParagraph = tw.li` text-lg my-4 `;

export default () => {
  return (
    <Container>
      <Heading>Writing Practice - Hindi Level 1 & 2</Heading>
      <FlexContainer>
        <LeftContainer>
          <ListParagraph>
            Builds WRITING, the 4th Pillar of Learning, in Children!
          </ListParagraph>

          <ListParagraph>
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Trace, Read, Write & Practice;{" "}
            </span>{" "}
            Covers the Level 1& 2 Hindi Beginner’s Syllabus
          </ListParagraph>

          <ListParagraph>
            Step-by-step guide – Enables productive engagement
          </ListParagraph>

          <ListParagraph>
            Thick 80 GSM paper helps erase and practice more times
          </ListParagraph>

          <ListParagraph>
            Standardized content (Level 1 & 2 Beginner’s Hindi) for the entire 2
            years
          </ListParagraph>

          <ListParagraph>
            Customizable: Practice the difficult items more to master
          </ListParagraph>

          <ListParagraph>
            Saves up to 100 hours of valuable time for Teachers / Mothers
          </ListParagraph>

          <ListParagraph>100% Colouful & Cost Effective</ListParagraph>

          <ListParagraph>
            Benefits Learning & Reinforcement –{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              Write; Review; Revise & Practice{" "}
            </span>
          </ListParagraph>

          <ListParagraph>
            Child develops positive study skills & habits to start working
            independently
          </ListParagraph>
        </LeftContainer>

        <RightContainer>
          <SliderLeiureTime />
        </RightContainer>
      </FlexContainer>

      <FlexContainer>
        <SecondLeftContainer>
          <SliderLeiureTimeNew />
        </SecondLeftContainer>

        <SecondRightContainer>
          <div>
            <ListParagraph>
              Child develops positive study skills & habits to start working
              independently
            </ListParagraph>

            <ListParagraph>
              Integrates Visual, Motor & Cognitive Processes
            </ListParagraph>

            <ListParagraph>
              <span
                style={{
                  color: "#1E3A8A",
                  fontWeight: "bold",
                  fontSize: "105%",
                }}
              >
                {" "}
                The first words children read are often the ones they write{" "}
              </span>
            </ListParagraph>

            <ListParagraph>
              Activates visual perception of letters – Builds Reading fluency &
              confidence
            </ListParagraph>

            <ListParagraph>
              Head Start –{" "}
              <span
                style={{
                  color: "#1E3A8A",
                  fontWeight: "bold",
                  fontSize: "105%",
                }}
              >
                {" "}
                Children gain in handwriting, spelling & punctuation{" "}
              </span>
            </ListParagraph>

            <ListParagraph>
              Parents learn what their Child is learning at School by reviewing
            </ListParagraph>

            <ListParagraph>Popular across 2150 towns in India</ListParagraph>

            <ListParagraph>
              35 Thousand Satisfied Teachers and 4L delighted Mothers
            </ListParagraph>
          </div>
        </SecondRightContainer>
      </FlexContainer>
    </Container>
  );
};
