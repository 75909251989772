import React, { useState } from "react";
import tw from "twin.macro";

import footersketch from "../Images/footersketch.png";
import footersketch1 from "../Images/footersketch1.png";
import footersketch2 from "../Images/footersketch2.png";
import footersketch3 from "../Images/footersketch3.png";
import FooterLogo from "../Images/logoWhite.png";
import Footerbg from "../Images/footerbg.png";
import Footerbg2 from "../Images/footerbg2.jpg";
import stars from "../Images/stars.png";

const PrimaryLink = tw.a`pb-1 md:text-lg text-sm cursor-pointer text-textprimary border-b border-transparent hocus:border-textprimary hocus:text-textprimary transition duration-300`;

const Container = tw.div`text-center  font-comicsans`;
const FooterContainer = tw.div` items-center md:w-full pb-16 md:pb-4 2xl:pb-10 md:justify-center md:flex text-left 2xl:my-4`;
const TopContainer = tw.div` items-center md:w-full  md:justify-center md:flex text-left`;

const FlexContainer = tw.div`flex w-full 2xl:mx-64 `;
const VerticalSections = tw.div`w-1/4 z-10 2xl:h-56 grid grid-cols-1 content-start md:mx-16`;
const TopVerticalSections = tw.div`w-1/4 md:mx-16`;

const ImageContainer = tw.div`my-2`;
const FooterLogoContainer = tw.div` -ml-14 my-4 `;
const FooterImageContainer = tw.div`-mt-4`;

const Heading = tw.div`font-semibold md:text-xl text-sm text-white`;
const Paragraph = tw.a` md:text-lg text-sm my-1 break-words text-white`;
const Subheading = tw.div`md:text-lg opacity-75 my-1 text-white`;
const FooterHeading = tw.div`md:text-lg text-sm text-white 2xl:-mt-14  md:-mt-10 -mt-12`;

const BGContainer = tw.div`bg-footerColor pt-8`;

const StarsImageContainer = tw.div` z-0 hidden md:flex justify-end scale-50 2xl:scale-100 translate-x-80 2xl:translate-x-0 transform translate-y-80 2xl:translate-y-72 2xl:mr-20`;
const StarsNewImageContainer = tw.div` z-0 hidden md:flex justify-center scale-75 2xl:translate-x-0 pl-40 transform translate-y-96 2xl:translate-y-Languages `;

export default () => {
  return (
    <Container>
      <StarsImageContainer>
        <img src={stars} />
      </StarsImageContainer>

      <StarsNewImageContainer>
        <img src={stars} />
      </StarsNewImageContainer>

      <img src={Footerbg} />
      <BGContainer>
        <TopContainer>
          <FlexContainer>
            <TopVerticalSections>
              <Heading>About 3H Learning</Heading>
              <ImageContainer>
                <img src={footersketch} width="80%" />
              </ImageContainer>
            </TopVerticalSections>

            <TopVerticalSections>
              <Heading>Grades</Heading>
              <ImageContainer>
                <img src={footersketch1} width="80%" />
              </ImageContainer>
            </TopVerticalSections>

            <TopVerticalSections>
              <Heading>Help and Support</Heading>
              <ImageContainer>
                <img src={footersketch2} width="80%" />
              </ImageContainer>
            </TopVerticalSections>

            <TopVerticalSections>
              <Heading>Contact Us</Heading>
              <ImageContainer>
                <img src={footersketch3} width="80%" />
              </ImageContainer>
            </TopVerticalSections>
          </FlexContainer>
        </TopContainer>

        <FooterContainer>
          <FlexContainer>
            <VerticalSections>
              <FooterLogoContainer>
                <img src={FooterLogo} />
              </FooterLogoContainer>

              <Paragraph>
                The Professional Promoters behind 3H Learning have empowered
                over 3150 Schools / Centers across India in the last 30+years.
              </Paragraph>
            </VerticalSections>

            <VerticalSections>
              <Paragraph href="/PreKG">PreKG</Paragraph>

              <Paragraph href="/LKG">LKG</Paragraph>

              <Paragraph href="/UKG">UKG</Paragraph>

              <Paragraph href="/STD1">STD1</Paragraph>

              <Paragraph href="/BeginnersHindi">Beginners Hindi</Paragraph>

              <Paragraph href="/BeginnersTamil">Beginners Tamil</Paragraph>

              <Paragraph href="/EnglishCursive">English Cursive</Paragraph>
            </VerticalSections>

            <VerticalSections>
              <Paragraph href="/ContactUs">Contact Us</Paragraph>
              <Paragraph href="/PrivacyPolicy">Privacy Policy</Paragraph>
              <Paragraph href="/Terms">Terms & Conditions</Paragraph>
              {/* <Paragraph href="/PaymentPolicy">PaymentPolicy</Paragraph> */}
            </VerticalSections>

            <VerticalSections>
              <Paragraph>
                3H Learning Private Limited <br /> "Dhanalakshmi Nivas"
                <br /> No: 37/77,T.P.Koil 1st Lane Ground Floor Triplicane
                Chennai-600 005
              </Paragraph>
              <Paragraph>
                044-4556 4344
                <br /> 044-4857 4344
              </Paragraph>
              <Paragraph> 3h@3hlearning.com</Paragraph>
            </VerticalSections>
          </FlexContainer>
        </FooterContainer>
      </BGContainer>

      <FooterImageContainer>
        <img src={Footerbg2} />
      </FooterImageContainer>
      <FooterHeading>
        © 2022 3H Learning. All Rights Reserved | Design by
        <PrimaryLink href="https://illusionspotline.com/"> ISL</PrimaryLink>
      </FooterHeading>
    </Container>
  );
};
