import React from "react";
import tw from "twin.macro";

import SliderLeiureTime from "../LKG/SliderLeisureTimeE";

const Container = tw.div`2xl:mx-64 mx-20 my-4`;
const FlexContainer = tw.div`flex items-center`;
const LeftContainer = tw.div`w-2/6 mr-48`;
const RightContainer = tw.div`w-2/4 `;

const Heading = tw.div`text-4xl mb-8 text-center font-black text-textprimary`;
const Paragraph = tw.div`text-lg `;

export default () => {
  return (
    <Container>
      <Heading>Memory Plus App</Heading>
      <FlexContainer>
        <LeftContainer>
          <SliderLeiureTime />
        </LeftContainer>
        <RightContainer>
          <Paragraph>
            This App can be played anytime during the year by the Child. This
            App enhances the Child’s Memory capacity.{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              ‘Memory’{" "}
            </span>
            is all about the quantum of information that can be{" "}
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              ‘retained’{" "}
            </span>
            and ‘
            <span
              style={{ color: "#1E3A8A", fontWeight: "bold", fontSize: "105%" }}
            >
              {" "}
              recalled’
            </span>{" "}
            when needed. Parents can also join their child in playing this
            Memory Plus activity. Moreover, the Child can play this App with the
            computer, with friends and also with global multi-players
          </Paragraph>
        </RightContainer>
      </FlexContainer>
    </Container>
  );
};
