import React from "react";
import tw from "twin.macro";

import SliderLeiureTime from "../BeginnerTamil/SliderLeisureTimeSix";

const Container = tw.div`my-4`;
const FlexContainer = tw.div` flex justify-center`;
const LeftContainer = tw.div` 2xl:mx-64 mx-20 w-4/12`;
const Heading = tw.div`text-4xl text-center mb-8 font-black text-textprimary`;

export default () => {
  return (
    <Container>
      <Heading>Tamil Learning Progress Set – Level 1 & 2</Heading>
      <FlexContainer>
        <LeftContainer>
          <SliderLeiureTime />
        </LeftContainer>
      </FlexContainer>
    </Container>
  );
};
