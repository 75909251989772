import React from "react";
import tw from "twin.macro";

import CounterComponent from "../Components/CounterComponent";
import WhatWeOffer from "../Components/WhatWeOffer";
import WelcomeNew from "../Components/WelcomeNew";

const Container = tw.div` font-comicsans`;

export default () => {
  return (
    <Container>
      <WelcomeNew />

      <CounterComponent />

      <WhatWeOffer />
    </Container>
  );
};
