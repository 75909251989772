/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
import React from "react";
import tw from "twin.macro";

import Image1 from "../Images/OurBooks/mem.png";
import Image2 from "../Images/OurBooks/kgPrep11.png";
import Image3 from "../Images/OurBooks/kgPrep22.png";
import Image4 from "../Images/OurBooks/kgPrep33.png";

const Container = tw.div` font-comicsans`;
const Sections = tw.div`flex items-center my-32`;
const LeftContainer = tw.div`w-1/2 `;
const RightContainer = tw.div`w-1/2 flex justify-center ml-48`;

const Table = tw.ul`list-disc ml-4`;

const FlexContainer = tw.div``;
const LeftTextContainer = tw.div`w-1/2 flex justify-end  `;
const RightTextContainer = tw.div`w-1/2 flex justify-center mr-40`;

const ImageContainer = tw.div`flex justify-center ml-60 `;
const LeftImageContainer = tw.div`flex justify-start`;
const RightImageContainer = tw.div`flex justify-end`;

const Heading = tw.div`text-4xl my-20 text-center font-semibold text-textprimary`;
const SubHeading = tw.div`text-2xl font-bold text-blue-500`;
const Paragraph = tw.div`text-lg my-2 opacity-70`;

export default () => {
  return (
    <Container>
      <Heading>Our Books</Heading>
      <Sections>
        <LeftContainer>
          <ImageContainer>
            <img src={Image1} width="70%" />
          </ImageContainer>
        </LeftContainer>

        <RightContainer>
          <FlexContainer>
            <SubHeading>Memory Plus App</SubHeading>

            <Paragraph>
              ‘Ability-to-Remember’, ie. ‘Memory’, plays a vital role in
              everybody's success.
            </Paragraph>

            <Paragraph>
              <Table>
                <li>In-take</li>
                <li>Retention</li>
                <li>Recall</li>
              </Table>
              The capability to absorb data more and more, retaining them in a
              structured format, and the ability to recall them when one wants,
              is what ‘memory’ is all about. This capability helps us in all
              walks of our life!
            </Paragraph>

            <Paragraph>How can one develop a ‘strong-memory’?</Paragraph>

            <Paragraph>
              ‘Muscles’ can be built with proper exercises in a gym. Similarly,
              one’s ‘memory’ can also be developed thru’ training. With proper
              dedication and care, anyone can develop their concentration power
              and memory.
            </Paragraph>

            <Paragraph>
              Isn’t it a very boring activity to develop / build one’s memory?
            </Paragraph>

            <Paragraph>
              Actually, it can be ‘fun’, as in the case of ‘Memory- Plus’ from
              3H Learning! The ‘Secret’, is in making the process interesting
              and at the same time fun filled. While the participants play the
              APP, unconsciously they develop strong memory – that is where in
              lies the design success of the APP.
            </Paragraph>
          </FlexContainer>
        </RightContainer>
      </Sections>

      <Sections>
        <LeftTextContainer>
          <FlexContainer>
            <SubHeading>KG Prep 1</SubHeading>

            <Paragraph>
              KG PREP 2 has activities to improve these skills
            </Paragraph>

            <Paragraph>
              <Table>
                <li> Cognitive </li>
                <li> Fine motor </li>
                <li> Observation </li>
                <li> Memory </li>
              </Table>
            </Paragraph>

            <Paragraph>
              KG Prep 2 has activities to reinforce these lessons/concepts
            </Paragraph>

            <Paragraph>
              <Table>
                <li> Letters: I-P </li>
                <li> Numbers 1-6 </li>
                <li> Counting 1-6 </li>
                <li> Nature </li>
                <li> Parts of the Body </li>
                <li> Things we ‘Do’ </li>
                <li> Vehicles </li>
                <li> Fruits </li>
                <li> Vegetables </li>
              </Table>
            </Paragraph>
          </FlexContainer>
        </LeftTextContainer>

        <RightContainer>
          <RightImageContainer>
            <img src={Image2} />
          </RightImageContainer>
        </RightContainer>
      </Sections>

      <Sections>
        <LeftContainer>
          <LeftImageContainer>
            <img src={Image3} />
          </LeftImageContainer>
        </LeftContainer>

        <RightTextContainer>
          <FlexContainer>
            <SubHeading>KG Prep 2</SubHeading>

            <Paragraph>
              KG PREP 2 has activities to improve these skills
            </Paragraph>

            <Paragraph>
              <Table>
                <li> Cognitive </li>
                <li> Fine motor </li>
                <li> Observation </li>
                <li> Memory </li>
              </Table>
            </Paragraph>

            <Paragraph>
              KG Prep 2 has activities to reinforce these lessons/concepts
            </Paragraph>

            <Paragraph>
              <Table>
                <li> Letters: I-P </li>
                <li> Numbers 6-10 </li>
                <li> Counting 6-10 </li>
                <li> Nature </li>
                <li> Parts of the Body </li>
                <li> Things we ‘Do’ </li>
                <li> Vehicles </li>
                <li> Fruits </li>
                <li> Vegetables </li>
              </Table>
            </Paragraph>
          </FlexContainer>
        </RightTextContainer>
      </Sections>

      <Sections>
        <LeftTextContainer>
          <FlexContainer>
            <SubHeading>KG Prep 3</SubHeading>

            <Paragraph>
              KG Prep 3 has activities to improve these skills
            </Paragraph>

            <Paragraph>
              <Table>
                <li> Cognitive </li>
                <li> Fine motor </li>
                <li> Observation </li>
                <li> Memory </li>
              </Table>
            </Paragraph>

            <Paragraph>
              KG Prep 3 has activities to reinforce these lessons/concepts
            </Paragraph>

            <Paragraph>
              <Table>
                <li> Letters: Q-Z </li>
                <li> Numbers: 1-10 </li>
                <li> Counting 1-10 </li>
                <li> Safety </li>
                <li> People at Work </li>
                <li> Animals </li>
                <li> Birds </li>
                <li> Insects </li>
              </Table>
            </Paragraph>
          </FlexContainer>
        </LeftTextContainer>

        <RightContainer>
          <RightImageContainer>
            <img src={Image4} />
          </RightImageContainer>
        </RightContainer>
      </Sections>
    </Container>
  );
};
