import React, { useState } from "react";
import tw from "twin.macro";
import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import SliderMemoryPlus from "../Components/OurApplications/SliderMemoryPlus";
import SliderKGPrep1 from "../Components/OurApplications/SliderKGPrep1";
import SliderKGPrep2 from "../Components/OurApplications/SliderKGPrep2";
import SliderKGPrep3 from "../Components/OurApplications/SliderKGPrep3";
import SliderAmazeWings from "../Components/OurApplications/SliderAmazeWings";

import voca1 from "../Images/Voc/logo_1024.png";
import voca2 from "../Images/Voc/icon_Duo_1024.png";
import voca3 from "../Images/Voc/icon_to_1024.png";

import logo from "../Images/googleplay.png";

import { FiPlayCircle } from "react-icons/fi";

const IconContainer = tw.div`font-comicsans mr-2 `;

const Container = tw.div`font-comicsans -mb-28 `;
const NewContainer = tw.div`font-comicsans my-28`;
const NewContainerBG = tw.div`font-comicsans my-28 p-8 2xl:px-64 md:px-32  bg-applicationColor`;
const SectionOneContainer = tw.div`md:flex md:mx-40 2xl:mx-64 justify-center`;
const TopContainer = tw.div` `;
const BottomContainer = tw.div` `;
const ImageContainer = tw.div`md:flex justify-center`;
const Tab1 = tw.div` md:w-1/3 mx-8 my-8`;
const Tab2 = tw.div` md:w-1/3 mx-8 my-8 `;
const Tab3 = tw.div` md:w-1/3 mx-8 my-8 `;

const SectionTwoContainer = tw.div`md:flex mx-8 md:mx-32 2xl:mx-64 justify-around items-center text-center `;
const VerticalSectionBGContainer = tw.div`md:flex px-14 md:px-28 2xl:px-64 py-8 items-center justify-around my-28  bg-applicationColor`;
const SectionBGContainer = tw.div`md:flex items-center justify-around`;
const VerticalLeftContainer = tw.div`md:w-1/4 mx-8 md:mx-0`;
const LeftContainer = tw.div`md:w-2/4  `;
const RightContainer = tw.div`md:w-2/4 md:mx-8`;

const ReactModalContainer = tw.div`fixed md:flex justify-center top-40 left-Grades md:w-full z-50`;

const LogoContainer = tw.a`-mr-24`;
const FlexContainer = tw.div`md:flex justify-center`;
const OutsideFlexContainer = tw.div`md:flex mt-8 justify-center`;
const OutsideBGFlexContainer = tw.div`md:flex mt-8 justify-center`;
const SectionHeading = tw.div`text-5xl my-16 text-center font-black text-textprimary`;

const HeadingLeftContainer = tw.div`text-5xl mb-12 text-center font-black text-textprimary`;
const Heading = tw.div`text-5xl my-8 text-center font-black text-textprimary`;
const HeadingBG = tw.div`text-5xl mb-12 text-center font-black text-blue-900`;
const HeadingVertical = tw.div`text-5xl my-6 text-center font-black text-blue-900`;
const Paragraph = tw.div`text-lg opacity-80 text-left`;
const VocaParagraph = tw.div`text-lg 2xl:mt-6 md:mt-12 mb-1 opacity-80 text-left`;
const VocaParagraphOne = tw.div`text-lg md:mt-12  md:mb-1 opacity-80 text-left`;
const VocaParagraphTwo = tw.div`text-lg 2xl:mt-6 md:mt-12 2xl:mb-1  md:mb-8 opacity-80 text-left`;
const SplitParagraph = tw.div`text-lg md:mx-4 opacity-80 text-left`;

const VerticalButtonContainer = tw.div`my-4`;
const NewPrimaryButton = tw.a` 2xl:mx-8 md:mx-4 p-2 text-xl cursor-pointer items-center text-white bg-blue-900 rounded `;
const PrimaryButton = tw.a`md:px-4 2xl:mx-8 md:mx-4 py-1 md:flex items-center text-xl cursor-pointer text-white bg-textprimary rounded `;
const BluePrimaryButton = tw.a`md:px-4 2xl:mx-8 md:mx-4 py-1 md:flex items-center text-xl cursor-pointer text-white bg-blue-900 rounded `;

export default () => {
  const [isOpen, setOpen] = useState(false);

  const [isMemoryPlus, MemoryPlus] = useState(false);
  const [isKGPrep1, KGPrep1] = useState(false);
  const [isKGPrep2, KGPrep2] = useState(false);
  const [isKGPrep3, KGPrep3] = useState(false);

  return (
    <Container>
      {/* ----------------------------------------------------------------*/}

      <SectionHeading>Our Applications</SectionHeading>

      <SectionOneContainer>
        <Tab1>
          <TopContainer>
            <ImageContainer>
              <img src={voca1} />
            </ImageContainer>
          </TopContainer>

          <BottomContainer>
            <VocaParagraphOne>
              <span style={{ color: "#EE741D" }}> ‘Vocabulary’ </span> is the
              most important
              <span style={{ color: "#EE741D" }}>
                {" "}
                ‘First-building-block’{" "}
              </span>{" "}
              responsible for Listening; Speaking; Reading & Writing… …& every
              other development in learning!! <br /> <br />
            </VocaParagraphOne>
          </BottomContainer>
          <NewPrimaryButton href="/VocaBuilderSolo">
            More Details
          </NewPrimaryButton>
        </Tab1>

        <Tab2>
          <TopContainer>
            <ImageContainer>
              <img src={voca2} />
            </ImageContainer>
          </TopContainer>

          <BottomContainer>
            <VocaParagraphTwo>
              {" "}
              <br />
              Presenting …. <br /> VocaBuilder – Duo <br />{" "}
              <span style={{ color: "#EE741D" }}> Start Competing </span> with
              Siblings / Friends / Relatives / You <br />
              <br />
            </VocaParagraphTwo>
          </BottomContainer>
          <NewPrimaryButton href="/VocaBuilderDuo">
            More Details
          </NewPrimaryButton>
        </Tab2>

        <Tab3>
          <TopContainer>
            <ImageContainer>
              <img src={voca3} />
            </ImageContainer>
          </TopContainer>

          <BottomContainer>
            <VocaParagraph>
              {" "}
              <br />
              Presenting …. <br /> VocaBuilder – ToTo <br /> Start Competing
              with Best Brains{" "}
              <span style={{ color: "#EE741D" }}> Globally </span> <br /> <br />{" "}
              <br />
            </VocaParagraph>
          </BottomContainer>
          <NewPrimaryButton href="/VocaBuilderToto">
            More Details
          </NewPrimaryButton>
        </Tab3>
      </SectionOneContainer>

      {/* ----------------------------------------------------------------*/}

      <VerticalSectionBGContainer>
        <VerticalLeftContainer>
          <SliderMemoryPlus />
        </VerticalLeftContainer>

        <RightContainer>
          <HeadingVertical>Memory Plus</HeadingVertical>
          <Paragraph>
            Memory Plus App <br /> <br />
            <span style={{ color: "#4189F5" }}>
              ‘Ability-to-Remember’, ie. ‘Memory’,
            </span>
            plays a vital role in everybody's success. <br /> <br /> ‘Memory’ is
            actually a combination of 3 things. <br />
            <span style={{ color: "#4189F5" }}>
              • In-take <br />
              • Retention <br />
              • Recall <br />
              <br />
            </span>
            The capability to absorb data more and more, retaining them in a
            structured format, and the ability to recall them when one wants, is
            what ‘memory’ is all about. This capability helps us in all walks of
            our life!
          </Paragraph>

          <ReactModalContainer>
            <React.Fragment>
              <ModalVideo
                style={{ width: 40 }}
                channel="youtube"
                youtube={{ mute: 1, autoplay: 0 }}
                isOpen={isMemoryPlus}
                videoId="kRubVFD0-bY"
                onClose={() => MemoryPlus(false)}
              />
            </React.Fragment>
          </ReactModalContainer>

          <VerticalButtonContainer>
            <FlexContainer>
              <BluePrimaryButton onClick={() => MemoryPlus(true)}>
                <IconContainer>
                  <FiPlayCircle size="35" />
                </IconContainer>
                Play Video
              </BluePrimaryButton>

              <LogoContainer href="https://play.google.com/store/apps/details?id=com.threehl.MemoryPlus">
                <img src={logo} width="60%" />
              </LogoContainer>
            </FlexContainer>
          </VerticalButtonContainer>
        </RightContainer>
      </VerticalSectionBGContainer>

      {/* ----------------------------------------------------------------*/}

      <NewContainer>
        <SectionTwoContainer>
          <LeftContainer>
            <HeadingLeftContainer>KGPrep 1</HeadingLeftContainer>
            <SliderKGPrep1 />

            <OutsideFlexContainer>
              <PrimaryButton onClick={() => KGPrep1(true)}>
                <IconContainer>
                  <FiPlayCircle size="35" />
                </IconContainer>
                Play Video
              </PrimaryButton>

              <LogoContainer href="https://play.google.com/store/apps/details?id=com.threehl.KGPrep1">
                <img src={logo} width="60%" />
              </LogoContainer>
            </OutsideFlexContainer>
          </LeftContainer>

          <RightContainer>
            <Paragraph>
              Why use Kg Prep Apps? The activities given here are educative,
              easy to comprehend, and fun to do. <br /> <br />
            </Paragraph>
            <FlexContainer>
              <SplitParagraph>
                <span style={{ color: "#EE741D" }}>
                  KG Prep 1 has activities to improve these skills
                </span>{" "}
                <br /> <br />
                • Cognitive <br />
                • Fine motor <br />
                • Observation <br />
                • Memory <br /> <br />
              </SplitParagraph>
              <SplitParagraph>
                <span style={{ color: "#EE741D" }}>
                  KG Prep 3 has activities to reinforce these lessons/concepts{" "}
                </span>
                <br /> <br />
                • Letters: A-H <br />
                • Numbers: 1-5 <br />
                • Counting 1-5 <br />
                • Safety <br />
                • People at Work <br />
                • Animals <br />
                • Birds <br />• Insects
              </SplitParagraph>
            </FlexContainer>

            <ReactModalContainer>
              <React.Fragment>
                <ModalVideo
                  style={{ width: 40 }}
                  channel="youtube"
                  youtube={{ mute: 1, autoplay: 0 }}
                  isOpen={isKGPrep1}
                  videoId="kRubVFD0-bY"
                  onClose={() => KGPrep1(false)}
                />
              </React.Fragment>
            </ReactModalContainer>
          </RightContainer>
        </SectionTwoContainer>
      </NewContainer>

      {/* ----------------------------------------------------------------*/}

      <NewContainerBG>
        <SectionBGContainer>
          <RightContainer>
            <Paragraph>
              Why use Kg Prep Apps? The activities given here are educative,
              easy to comprehend, and fun to do. <br /> <br />
            </Paragraph>
            <FlexContainer>
              <SplitParagraph>
                <span style={{ color: "#4189F5" }}>
                  KG Prep 2 has activities to improve these skills
                </span>{" "}
                <br /> <br />
                • Cognitive <br />
                • Fine motor <br />
                • Observation <br />
                • Memory <br /> <br />
              </SplitParagraph>
              <SplitParagraph>
                <span style={{ color: "#4189F5" }}>
                  KG Prep 2 has activities to reinforce these lessons/concepts{" "}
                </span>
                <br /> <br />
                • Letters: I-P <br />
                • Numbers 6-10 <br />
                • Counting 6-10 <br />
                • Nature <br />
                • Parts of the Body <br />
                • Things we ‘Do’ <br />
                • Vehicles <br />
                • Fruits <br />
                • Vegetables <br />
              </SplitParagraph>
            </FlexContainer>

            <ReactModalContainer>
              <React.Fragment>
                <ModalVideo
                  style={{ width: 40 }}
                  channel="youtube"
                  youtube={{ mute: 1, autoplay: 0 }}
                  isOpen={isKGPrep2}
                  videoId="kRubVFD0-bY"
                  onClose={() => KGPrep2(false)}
                />
              </React.Fragment>
            </ReactModalContainer>
          </RightContainer>

          <LeftContainer>
            <HeadingBG>KGPrep 2</HeadingBG>
            <SliderKGPrep2 />

            <OutsideBGFlexContainer>
              <BluePrimaryButton onClick={() => KGPrep2(true)}>
                <IconContainer>
                  <FiPlayCircle size="35" />
                </IconContainer>
                Play Video
              </BluePrimaryButton>

              <LogoContainer href="https://play.google.com/store/apps/details?id=com.threehl.kgprep2">
                <img src={logo} width="60%" />
              </LogoContainer>
            </OutsideBGFlexContainer>
          </LeftContainer>
        </SectionBGContainer>
      </NewContainerBG>

      {/* ----------------------------------------------------------------*/}

      <NewContainer>
        <SectionTwoContainer>
          <LeftContainer>
            <HeadingLeftContainer>KGPrep 3</HeadingLeftContainer>
            <SliderKGPrep3 />
            <OutsideFlexContainer>
              <PrimaryButton onClick={() => KGPrep3(true)}>
                <IconContainer>
                  <FiPlayCircle size="35" />
                </IconContainer>
                Play Video
              </PrimaryButton>

              <LogoContainer href="https://play.google.com/store/apps/details?id=com.threehl.KGPrep3">
                <img src={logo} width="60%" />
              </LogoContainer>
            </OutsideFlexContainer>
          </LeftContainer>

          <RightContainer>
            <Paragraph>
              Why use Kg Prep Apps? The activities given here are educative,
              easy to comprehend, and fun to do. <br /> <br />
            </Paragraph>
            <FlexContainer>
              <SplitParagraph>
                <span style={{ color: "#EE741D" }}>
                  KG Prep 3 has activities to improve these skills
                </span>{" "}
                <br /> <br />
                • Cognitive <br />
                • Fine motor <br />
                • Observation <br />
                • Memory <br /> <br />
              </SplitParagraph>
              <SplitParagraph>
                <span style={{ color: "#EE741D" }}>
                  KG Prep 3 has activities to reinforce these lessons/concepts{" "}
                </span>
                <br /> <br />
                • Letters: Q-Z <br />
                • Numbers: 1-10 <br />
                • Counting 1-10 <br />
                • Safety <br />
                • People at Work <br />
                • Animals <br />
                • Birds <br />• Insects
              </SplitParagraph>
            </FlexContainer>

            <ReactModalContainer>
              <React.Fragment>
                <ModalVideo
                  style={{ width: 40 }}
                  channel="youtube"
                  youtube={{ mute: 1, autoplay: 0 }}
                  isOpen={isKGPrep3}
                  videoId="kRubVFD0-bY"
                  onClose={() => KGPrep3(false)}
                />
              </React.Fragment>
            </ReactModalContainer>
          </RightContainer>
        </SectionTwoContainer>
      </NewContainer>

      {/* ----------------------------------------------------------------*/}

      {/* <NewContainerBG>
        <SectionBGContainer>
          <RightContainer>
            <Paragraph>
              Amaze Wings comes with many extra benefits! <br /> <br />
            </Paragraph>
            <FlexContainer>
              <SplitParagraph>
                <span style={{ color: "#4189F5" }}>
                  Individual and Group Activities
                </span>{" "}
                <br /> <br />
                • Physical Skills <br />
                • Memory Skills <br />
                • Cognitive Skills <br />
                • Social and Emotional Skills <br />
                • Sensory Skills <br /> <br />
              </SplitParagraph>
              <SplitParagraph>
                <span style={{ color: "#4189F5" }}>
                  Comprehensive Premium Coverage:{" "}
                </span>
                <br /> <br />
                • Classic Edu-Pack Combo - Look no beyond! <br />
                • 10 Must Pre-Kindergartn Skills <br />
                • 3 Timester Books <br />
                • 3 KG Prep Android Apps: Self-Learn model <br />
                • IntelliGames: Develop Non-Scholastic Skills <br />
                • Habit Formation & Sustenance <br />
              </SplitParagraph>
            </FlexContainer>

            <ReactModalContainer>
              <React.Fragment>
                <ModalVideo
                  style={{ width: 40 }}
                  channel="youtube"
                  youtube={{ mute: 1, autoplay: 0 }}
                  isOpen={isKGPrep2}
                  videoId="kRubVFD0-bY"
                  onClose={() => KGPrep2(false)}
                />
              </React.Fragment>
            </ReactModalContainer>
          </RightContainer>

          <LeftContainer>
            <HeadingBG>AMAZE WINGS</HeadingBG>
            <SliderAmazeWings />
          </LeftContainer>
        </SectionBGContainer>
      </NewContainerBG> */}

      {/* ----------------------------------------------------------------*/}
    </Container>
  );
};
