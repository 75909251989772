import tw from "twin.macro";
import React from "react";

import Practiz from "../Images/OurSponsors/Practiz.png";
import VocabuilderSolo from "../Images/OurSponsors/VocabuilderSolo.png";
import DB from "../Images/OurSponsors/DB.png";
import ACE from "../Images/OurSponsors/ACE.png";
import LearningProgressSet from "../Images/OurSponsors/LPS.png";

import AMAZE from "../Images/OurSponsors/AMAZE.png";
import Intelligame from "../Images/OurSponsors/Intelligame.png";
import KGCoach from "../Images/OurSponsors/KGCoach.png";

const Paragraph = tw.div`text-white md:text-lg text-center mb-4 mt-4 mx-8 md:font-semibold`;
const Heading = tw.div`2xl:text-5xl text-4xl text-center my-6 font-black text-blue-900`;

const Container = tw.div``;
const ImageContainer = tw.div` flex justify-center content-center`;

const GridContainer = tw.div`md:flex justify-center 2xl:mx-80 md:mx-20 mt-12 text-left`;

const TabContainer1 = tw.div`bg-gray-400  cursor-default items-center md:w-1/3 mx-4 rounded-3xl  `;
const TabContainer2 = tw.div`bg-yellow-600  cursor-default flex items-center md:w-1/3 mx-4 my-2 md:my-2 rounded-3xl  `;
const TabContainer3 = tw.div`bg-green-700   cursor-default flex items-center md:w-1/3 mx-4 rounded-3xl  `;
const TabContainer4 = tw.div`bg-gray-600  cursor-default items-center md:w-1/3 mx-4 rounded-3xl  `;
const TabContainer5 = tw.div`bg-pink-500   cursor-default items-center md:w-1/3 mx-4  rounded-3xl `;
const TabContainer6 = tw.div`bg-blue-500   cursor-default items-center md:w-1/3 mx-4 rounded-3xl `;
const TabContainer7 = tw.div`bg-green-400  cursor-default items-center md:w-1/3 mx-4 rounded-3xl `;
const TabContainer8 = tw.div`bg-yellow-600   cursor-default items-center md:w-1/3 mx-4  rounded-3xl  `;
const TabContainer9 = tw.div`bg-red-600   cursor-default items-center md:w-1/3 mx-4 rounded-3xl  `;

const BottomContainer = tw.div` pt-4 pb-16 h-1/2 flex items-center `;
const BottomContainerTab1 = tw.div` pt-4 pb-4 h-1/2 flex items-center `;
const TopContainer = tw.div`items-center flex justify-center h-1/2 mt-4 pt-4 md:pt-0`;

function App() {
  return (
    <>
      <Container>
        <Heading>Key Features of our Edu-Packs:</Heading>
        <GridContainer>
          <TabContainer1>
            <TopContainer>
              <ImageContainer>
                <img src={AMAZE} width="50%" />
              </ImageContainer>
            </TopContainer>
            <BottomContainerTab1>
              <Paragraph>
                Specially designed Textbooks with thick quality paper
              </Paragraph>
            </BottomContainerTab1>
          </TabContainer1>

          <TabContainer2>
            <Paragraph>
              Lessons Multimedia –{" "}
              <span style={{ color: "#111827" }}>
                {" "}
                Thematic digital lessons,{" "}
              </span>{" "}
              animated with Cartoon Characters, that are{" "}
              <span style={{ color: "#111827" }}>
                {" "}
                integrated with every chapter / concept in the textbook;
              </span>{" "}
              helps Teachers to impart lessons more effectively and ensures to
              achieve standardized delivery quality
            </Paragraph>
          </TabContainer2>

          <TabContainer3>
            <Paragraph>
              Application Multimedia – Special{" "}
              <span style={{ color: "#111827" }}> ‘Digital - Practical’ </span>{" "}
              ensures that no child is left behind and moves into the next week
              ONLY after clearly understanding concepts till that week – Sort of
              informal ‘Formative – Assessments’ available 36 times in a year!
            </Paragraph>
          </TabContainer3>
        </GridContainer>

        {/* ------------------------------------------------------------------------------ */}

        <GridContainer>
          <TabContainer4>
            <TopContainer>
              <ImageContainer>
                <img src={ACE} width="60%" />
              </ImageContainer>
            </TopContainer>
            <BottomContainer>
              <Paragraph>
                Arguably the highest selling Worksheets in the entire country! A
                great tool to{" "}
                <span style={{ color: "#111827" }}>
                  {" "}
                  identify the ‘learning-gaps’{" "}
                </span>{" "}
                in the children and plugs-them immediately
              </Paragraph>
            </BottomContainer>
          </TabContainer4>

          <TabContainer5>
            <TopContainer>
              <ImageContainer>
                <img src={Practiz} width="60%" />
              </ImageContainer>
            </TopContainer>
            <BottomContainer>
              <Paragraph>
                Builds ‘Writing’ skills -{" "}
                <span style={{ color: "#111827" }}>
                  {" "}
                  the 4thPillar of Learning!{" "}
                </span>{" "}
                Saves up to 400 hours in year for Teachers from repetitive and
                monotonous task of writing in the notebooks of all their
                students!
              </Paragraph>
            </BottomContainer>
          </TabContainer5>

          <TabContainer6>
            <TopContainer>
              <ImageContainer>
                <img src={LearningProgressSet} width="40%" />
              </ImageContainer>
            </TopContainer>
            <BottomContainer>
              <Paragraph>
                <span style={{ color: "#111827" }}>
                  {" "}
                  Colorful way to conduct Summative Assessments{" "}
                </span>{" "}
                – 6 times in a year
              </Paragraph>
            </BottomContainer>
          </TabContainer6>
        </GridContainer>

        {/* ------------------------------------------------------------------------------ */}

        <GridContainer>
          <TabContainer7>
            <TopContainer>
              <ImageContainer>
                <img src={Intelligame} width="75%" />
              </ImageContainer>
            </TopContainer>
            <BottomContainer>
              <Paragraph>
                Gamification of education - What{" "}
                <span style={{ color: "#111827" }}>
                  {" "}
                  ‘learning-in-the-future’{" "}
                </span>{" "}
                is going to be all about!
              </Paragraph>
            </BottomContainer>
          </TabContainer7>

          <TabContainer8>
            <TopContainer>
              <ImageContainer>
                <img src={DB} width="70%" />
              </ImageContainer>
            </TopContainer>
            <BottomContainer>
              <Paragraph>
                Conversational English Program implemented by any School{" "}
                <span style={{ color: "#111827" }}>
                  {" "}
                  with their own Teachers (with or without English proficiency).{" "}
                </span>{" "}
                An effortless{" "}
                <span style={{ color: "#111827" }}>
                  {" "}
                  ‘Multi-Sensory’ learning methodology{" "}
                </span>{" "}
                creates an ‘English-Environment’ that helps the students speak
                in English{" "}
                <span style={{ color: "#111827" }}>
                  {" "}
                  as easy as speaking in one’s mother tongue.
                </span>
              </Paragraph>
            </BottomContainer>
          </TabContainer8>

          <TabContainer9>
            <TopContainer>
              <ImageContainer>
                <img src={VocabuilderSolo} width="60%" />
              </ImageContainer>
            </TopContainer>
            <BottomContainer>
              <Paragraph>
                Builds a strong{" "}
                <span style={{ color: "#111827" }}>
                  ‘Vocabulary’ - the fundamental ‘building-block’{" "}
                </span>
                of all other learning – Listening, Speaking, Reading and Writing
                (LSRW) can be developed only by building one’s Vocabulary.
              </Paragraph>
            </BottomContainer>
          </TabContainer9>
        </GridContainer>
      </Container>
    </>
  );
}

export default App;
