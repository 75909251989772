import "./../Carousel.css";
import tw from "twin.macro";
import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Slider from "react-slick";
import image1 from "../../Images/LKG/ACEEnglishMathematicsEVSGk/01.jpg";
import image2 from "../../Images/LKG/ACEEnglishMathematicsEVSGk/02.jpg";
import image3 from "../../Images/LKG/ACEEnglishMathematicsEVSGk/03.jpg";
import image4 from "../../Images/LKG/ACEEnglishMathematicsEVSGk/04.jpg";
import image5 from "../../Images/LKG/ACEEnglishMathematicsEVSGk/05.jpg";
import image6 from "../../Images/LKG/ACEEnglishMathematicsEVSGk/06.jpg";
import image7 from "../../Images/LKG/ACEEnglishMathematicsEVSGk/07.jpg";
import image8 from "../../Images/LKG/ACEEnglishMathematicsEVSGk/08.jpg";
import image9 from "../../Images/LKG/ACEEnglishMathematicsEVSGk/09.jpg";
import image10 from "../../Images/LKG/ACEEnglishMathematicsEVSGk/10.jpg";
import image11 from "../../Images/LKG/ACEEnglishMathematicsEVSGk/11.jpg";

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
];

const Container = tw.div``;
const SliderContainer = tw.div` text-blue-900 text-center`;

const FixedContainer = tw.div`fixed mx-auto md:w-2/4 inset-x-0 top-10 inset-0 flex justify-center items-center`;

const IconContainer = tw.div` flex justify-end `;
const ModalContainer = tw.div`m-20  z-50 flex justify-center`;

const ShadowContainer = tw.div`bg-black fixed opacity-50 w-full h-full left-0 top-0 z-50`;
const Previousbutton = tw.button`text-black `;

function App() {
  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 1500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  const [open_modal, set_open_modal] = useState(false);
  const [image_to_show, set_image_to_show] = useState(null);
  const OpenModal = (image) => {
    set_open_modal(!open_modal);
    set_image_to_show(image);
  };
  return (
    <Container>
      <SliderContainer>
        <Slider {...settings}>
          {images.map((img, idx) => (
            <img src={img} onClick={(e) => OpenModal(img)} alt={img} />
          ))}
        </Slider>
      </SliderContainer>
      {open_modal === true ? (
        <FixedContainer>
          <ModalContainer>
            <img src={image_to_show} width="100%" />
            <IconContainer onClick={() => set_open_modal(false)}>
              <AiOutlineCloseCircle size="48" />
            </IconContainer>
          </ModalContainer>
        </FixedContainer>
      ) : null}
    </Container>
  );
}

export default App;
