import "./../Carousel.css";
import tw from "twin.macro";
import Slider from "react-slick";
import image1 from "../../Images/Voc/vocabuilder_solo_SS1.jpg";
import image2 from "../../Images/Voc/vocabuilder_solo_SS2.jpg";
import image3 from "../../Images/Voc/vocabuilder_solo_SS3.jpg";
import image4 from "../../Images/Voc/vocabuilder_solo_SS4.jpg";
import image5 from "../../Images/Voc/vocabuilder_solo_SS5.jpg";
import image6 from "../../Images/Voc/vocabuilder_solo_SS6.jpg";
import image7 from "../../Images/Voc/vocabuilder_solo_SS7.jpg";
import image8 from "../../Images/Voc/vocabuilder_solo_SS8.jpg";
import image9 from "../../Images/Voc/vocabuilder_solo_SS9.jpg";

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
];

const Container = tw.div``;
const SliderContainer = tw.div``;

function App() {
  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 1500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <Container>
      <SliderContainer>
        <Slider {...settings}>
          {images.map((img, idx) => (
            <img src={img} alt={img} />
          ))}
        </Slider>
      </SliderContainer>
    </Container>
  );
}

export default App;
