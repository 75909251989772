import tw from "twin.macro";
import React, { Component } from "react";

import ListIcon from "../Images/pencil.png";

const Container = tw.div`2xl:mx-80 md:-mb-20 `;
const FlexContainer = tw.div`md:flex `;
const TabContainer1 = tw.div`md:w-1/3 md:ml-4 md:mx-0 my-4 mx-4 bg-red-400 rounded-3xl py-8  pl-4`;
const TabContainer2 = tw.div`md:w-1/3 md:mx-4 my-4 mx-4 bg-blue-400 rounded-3xl py-8 pl-4`;
const TabContainer3 = tw.div`md:w-1/3 md:mr-4 md:mx-0 my-4 mx-4 bg-green-400 rounded-3xl py-8 pl-4 `;
// const TabContainer3 = tw.div`w-1/3 mx-8 my-12`;

const SectionHeading = tw.h1`text-5xl text-center mb-6 2xl:mb-4 text-blue-900 font-black `;
const SecondSectionHeading = tw.h1`text-5xl  mb-4 text-center text-blue-800 font-black `;
const Heading = tw.h2`text-2xl md:mb-6 2xl:mb-0 text-white font-bold `;

const ListContainer = tw.div`flex items-center my-1`;
const Paragraph = tw.div`text-lg pl-2 2xl:my-3 my-2 text-white  `;
const ListIconContainer = tw.div`2xl:w-4 w-6`;
const ListParagraph = tw.li` pl-4 my-4 opacity-75`;

const PrimaryButton = tw.a` px-4 py-2 text-base cursor-pointer text-white bg-blue-400 rounded`;

function App() {
  return (
    <Container>
      <SectionHeading>Presenting 3H Learning!</SectionHeading>

      <FlexContainer>
        <TabContainer1>
          <Heading>We understand the :</Heading>
          <ListContainer>
            <ListIconContainer>
              <img src={ListIcon} />
            </ListIconContainer>
            <Paragraph>‘Challenges’ faced by Schools Managements</Paragraph>
          </ListContainer>

          {/* ------------------- */}

          <ListContainer>
            <ListIconContainer>
              <img src={ListIcon} />
            </ListIconContainer>
            <Paragraph>‘Compulsions’ of modern-day Teachers</Paragraph>
          </ListContainer>

          {/* ------------------- */}

          <ListContainer>
            <ListIconContainer>
              <img src={ListIcon} />
            </ListIconContainer>
            <Paragraph>
              Difficulties and Emotional-Ambitions of Parents
            </Paragraph>
          </ListContainer>

          {/* ------------------- */}

          <ListContainer>
            <ListIconContainer>
              <img src={ListIcon} />
            </ListIconContainer>
            <Paragraph>Needs’ of the modern day Children.</Paragraph>
          </ListContainer>

          {/* ------------------- */}
        </TabContainer1>

        <TabContainer2>
          <Heading>We can offer :</Heading>

          <ListContainer>
            <ListIconContainer>
              <img src={ListIcon} />
            </ListIconContainer>
            <Paragraph>Edu-Packs that are ‘different’</Paragraph>
          </ListContainer>

          {/* ------------------- */}

          <ListContainer>
            <ListIconContainer>
              <img src={ListIcon} />
            </ListIconContainer>
            <Paragraph>
              Holistic Education that is ‘Multi-dimensional’
            </Paragraph>
          </ListContainer>

          {/* ------------------- */}

          <ListContainer>
            <ListIconContainer>
              <img src={ListIcon} />
            </ListIconContainer>
            <Paragraph>‘Technology’ used responsibly</Paragraph>
          </ListContainer>

          {/* ------------------- */}

          <ListContainer>
            <ListIconContainer>
              <img src={ListIcon} />
            </ListIconContainer>
            <Paragraph>Traditional ‘Values’ included</Paragraph>
          </ListContainer>

          {/* ------------------- */}

          <ListContainer>
            <ListIconContainer>
              <img src={ListIcon} />
            </ListIconContainer>
            <Paragraph>
              ‘Variety-Offering’ to sustain Student Interest
            </Paragraph>
          </ListContainer>

          {/* ------------------- */}

          <ListContainer>
            <ListIconContainer>
              <img src={ListIcon} />
            </ListIconContainer>
            <Paragraph>
              ‘Ease-of-Understanding & Use’ products to the Teachers
            </Paragraph>
          </ListContainer>

          {/* ------------------- */}

          <ListContainer>
            <ListIconContainer>
              <img src={ListIcon} />
            </ListIconContainer>
            <Paragraph>Tools to avoid rote-learning</Paragraph>
          </ListContainer>

          {/* ------------------- */}
        </TabContainer2>

        <TabContainer3>
          <Heading>We can help a School to :</Heading>
          <ListContainer>
            <ListIconContainer>
              <img src={ListIcon} />
            </ListIconContainer>
            <Paragraph>
              Handle their local competition with confidence
            </Paragraph>
          </ListContainer>

          {/* ------------------- */}

          <ListContainer>
            <ListIconContainer>
              <img src={ListIcon} />
            </ListIconContainer>
            <Paragraph>
              Offer solutions for all of a Teacher’s academic needs in a year
            </Paragraph>
          </ListContainer>

          {/* ------------------- */}

          <ListContainer>
            <ListIconContainer>
              <img src={ListIcon} />
            </ListIconContainer>
            <Paragraph>Have these Premium Packs at Popular Pricing</Paragraph>
          </ListContainer>

          {/* ------------------- */}

          <ListContainer>
            <ListIconContainer>
              <img src={ListIcon} />
            </ListIconContainer>
            <Paragraph>
              Pick and choose what they want and the quantity they need
            </Paragraph>
          </ListContainer>

          {/* ------------------- */}
        </TabContainer3>
      </FlexContainer>
    </Container>
  );
}

export default App;
