import React from "react";
import tw from "twin.macro";

import SliderLeiureTime from "../BeginnerHindi/SliderLeisureTimeSix";
const Container = tw.div` 2xl:mx-64 mx-20 my-4`;
const FlexContainer = tw.div`flex items-center `;
const LeftContainer = tw.div`w-3/4 `;
const RightContainer = tw.div`w-1/4 ml-32`;

const Heading = tw.div`text-4xl text-center mb-8 font-black text-textprimary`;
const Paragraph = tw.div`text-lg my-4 `;

export default () => {
  return (
    <Container>
      <FlexContainer>
        <LeftContainer>
          <Heading>Learning Progress Set – Level 1 & 2 Hindi</Heading>
          <div>
            <Paragraph>
              Conducting regular{" "}
              <span
                style={{
                  color: "#1E3A8A",
                  fontWeight: "bold",
                  fontSize: "105%",
                }}
              >
                {" "}
                ‘Summative-Assessments’{" "}
              </span>{" "}
              is always a cumbersome job. It takes a lot of time of the Lead
              Teacher (with experience to create SA papers). When it comes to
              duplication from the master dummy, the output is always not so
              good looking black & white question papers. Hindi type setting is
              also very difficult in S.India.
              <br /> <br />
              Learning Progress Sets (6 times in a year) from 3H Learning are
              colorful, cost-effective and time-saver. A set of LPS (8 pages)
              contains both{" "}
              <span
                style={{
                  color: "#1E3A8A",
                  fontWeight: "bold",
                  fontSize: "105%",
                }}
              >
                {" "}
                written as well as oral assessments sheets.{" "}
              </span>{" "}
              An entire set (around 48 pages in a year) to conduct six exams
              costs less than a set of puris in a good restaurant. Children will
              love it and Parents feel proud with this professional output. A
              Progress Card also given at the beginning of the year.{" "}
              <span
                style={{
                  color: "#1E3A8A",
                  fontWeight: "bold",
                  fontSize: "105%",
                }}
              >
                {" "}
                Schools can build a quality image{" "}
              </span>{" "}
              around their brand.
            </Paragraph>
          </div>
        </LeftContainer>
        <RightContainer>
          <SliderLeiureTime />
        </RightContainer>
      </FlexContainer>
    </Container>
  );
};
